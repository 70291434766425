import { useState, useEffect } from 'react';
import logo from './img/logo.png'
import Server from './Servidor';

export default function Recuperacao() {

    const verde = '#88d374'

    const [display_form, setdisplay_form] = useState(false)
    const [display_load, setdisplay_load] = useState(true)
    const [display_ok, setdisplay_ok] = useState(false)
    const [display_erro, setdisplay_erro] = useState(false)

    const [user, setuser] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    //coloca o load e verifica o token antes de abrir o form

    async function Verificar() {
        try {
            const resposta = await Server({ codigo: 'verificar_token_forgot', token });
            setdisplay_load(false)

            if (resposta.error) {
                console.log(resposta.error)
                setdisplay_erro(true)
            } else {
                console.log(resposta.user)
                setuser(resposta.user)
                setdisplay_form(true)
            }

        } catch (error) {
            console.log(error)
            setdisplay_load(false)
            setdisplay_erro(true)
        }
    }

    useEffect(() => {
        Verificar()
    }, []);

    async function Alterar() {
        if (password === confirmPassword) {
            if (password.length < 6) {
                alert('A senha deve ter pelo menos 6 caracteres')
            } else {
                setdisplay_form(false)
                setdisplay_load(true)

                try {
                    const resposta = await Server({ codigo: 'alterar_senha_forgot', user, senha: password });
                    setdisplay_load(false)

                    if (resposta.error) {
                        console.log(resposta.error)
                        setdisplay_form(true)
                        alert('Algum erro ocorreu!')
                    } else {
                        setdisplay_ok(true)
                    }
                } catch (error) {
                    console.log(error)
                    setdisplay_load(false)
                    setdisplay_form(true)
                    alert('Algum erro ocorreu!')
                }
            }
        } else {
            alert('As senhas não coincidem!');
        }
    }

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    return (
        <div style={{ display: 'flex', height: '100vh', width: '100vw', justifyContent: 'center', backgroundColor: verde, flexDirection: 'column', alignItems: 'center' }}>
            {display_form ?
                <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img className="link_img" src={logo} />
                    <h1 className="link_h1">Recuperação de senha</h1>

                    <div className="recuperacao_form">
                        <input
                            type="password"
                            placeholder="Nova Senha"
                            value={password}
                            onChange={handlePasswordChange}
                            required />

                        <input
                            type="password"
                            placeholder="Confirme a Nova Senha"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required />

                        <button type="submit" onClick={() => Alterar()}>Redefinir Senha</button>
                    </div>
                </div>
                : null}

            {display_load ?
                <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <span className="load_recuperacao" />
                </div>
                : null}

            {display_ok ?
                <div>
                    <span style={{ color: '#000', fontSize: 26, fontWeight: 600, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Senha alterada com sucesso!</span>
                </div>
                : null}

            {display_erro ?
                <div>
                    <span style={{ color: '#000', fontSize: 26, fontWeight: 600, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Algum erro ocorreu!</span>
                </div>
                : null}
        </div>
    );
}
