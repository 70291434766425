async function Server(params) {

  //normal
  //https://efizip-h5hippfzwa-uc.a.run.app

  //testes
  //https://estrutura-h5hippfzwa-uc.a.run.app
  try {
    const server = await fetch('https://efizip-h5hippfzwa-uc.a.run.app', {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const resposta = await server.json();
    return resposta;

  } catch (error) {
    console.error('Erro na consulta ao servidor:', error.message);
    throw error;
  }
}

export default Server;