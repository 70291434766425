import React from "react";
import { FcGallery } from "react-icons/fc";
import { CiImageOn } from "react-icons/ci";

const CardProduto = ({ item, detalhes }) => {

    return (
        item.status ? null :
            <div onClick={() => detalhes()} className="modelo2_card_produto">

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <span className="card_produto_titulo">
                            {item.titulo}
                        </span>

                        <span style={{ width: '100%', marginTop: 10, fontSize: 16, color: '#999' }}>{item.descricao}</span>

                        <span style={{ marginTop: 10, fontSize: 17, color: '#000' }}>{item.tamanho}</span>

                        <span style={{ marginTop: 10, fontSize: 24, fontWeight: 700, fontFamily: 'PatrickHand', color: '#000' }}>R$ {item.precopromo ? item.precopromo : item.preco}</span>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {item.icone ?
                            <img className="modelo2_card_produto_img" src={item.icone} alt="Imagem do produto" />
                            :
                            <div className="modelo2_card_produto_img">
                                <CiImageOn className="modelo2_card_produto_noimg" />
                            </div>}
                    </div>
                </div>

            </div>
    )
}

export default React.memo(CardProduto)