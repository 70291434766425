import logo from './img/logo.png'

export default function Links() {

    const verde = '#88d374'

    return (
        <div style={{ display: 'flex', height: '100vh', width: '100vw', justifyContent: 'center', backgroundColor: verde, flexDirection: 'column', alignItems: 'center' }}>
            <img className="link_img" src={logo} />
            <h1 className="link_h1">Encurtagro</h1>

            <a href="https://play.google.com/store/apps/details?id=com.perseguidu.krl3" target="_blank" className="link_bloco">
                <h2 className="link_h2">Aplicativo (android)</h2>
            </a>
            <a href="https://apps.apple.com/us/app/encurtagro/id6443944425?itsct=apps_box_link&itscg=30200" target="_blank" className="link_bloco">
                <h2 className="link_h2">Aplicativo (ios)</h2>
            </a>
            <a href="/" target="_blank" className="link_bloco">
                <h2 className="link_h2">Nosso site</h2>
            </a>
            <a href="http://api.whatsapp.com/send?phone=5516999751411" target="_blank" className="link_bloco">
                <h2 className="link_h2">Contato whatsapp</h2>
            </a>
        </div>
    );
}
