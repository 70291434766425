import React, { createContext, useContext } from 'react';

const DadosContext = createContext();

export default function DadosProvider({ children }) {
    const dadosmain = []

    return (
        <DadosContext.Provider value={{ dadosmain }}>
            {children}
        </DadosContext.Provider>
    );
}

export function useDados() {
    const context = useContext(DadosContext);
    const { dadosmain } = context;
    return { dadosmain };
}