import { FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import logoguandu from './img/logoguandu2.png'
import rosanah from './img/rosanah.png'
import { FaBasketShopping } from "react-icons/fa6";
import { RiEBike2Line } from "react-icons/ri";
import { FiMapPin } from "react-icons/fi";
import round1 from './img/round1.png'
import round2 from './img/round2.png'
import round3 from './img/round3.png'
import quem1 from './img/quem1.png'
import quem2 from './img/quem2.png'
import quem3 from './img/quem3.png'
import feijaoguandu from './img/feijaoguandu.png'
import betins from './img/betins.png'
import cabocla from './img/cabocla.png'
import granola from './img/granola.png'
import vegana from './img/vegana.png'
import redondo from './img/redondo.png'
import cogumelo from './img/cogumelo.png'
import sattva from './img/sattva.png'
import trigallia from './img/trigallia.png'
import huertita from './img/huertita.png'
import catarina from './img/catarina.png'
import copaiba from './img/copaiba.png'
import expansao from './img/expansao.png'
import gourmet from './img/gourmet.png'
import komfuzza from './img/komfuzza.png'
import footer from './img/guandu_footer.png'

export default function Guandu() {

    document.title = 'Rede Guandu'

    const verde = '#88d374'
    const branco = '#E6E6E6'
    const bege = '#F2F0C6'
    const verde2 = '#A5E800'
    const verde3 = '#334C46'

    const header = {
        color: '#000',
        paddingTop: 25,
        paddingBottom: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: verde3
    }

    const contato = {
        fontSize: 20,
        textAlign: 'center',
        color: '#000',
        marginLeft: '10px'
    }

    const rounds = {
        width: 170,
        marginTop: 15,
        marginBottom: 15
    }

    const produtores_logo = {
        width: 120
    }

    const telefone = '(19) 99893 - 2843'
    const wpp = 'https://api.whatsapp.com/send?phone=' + '55' + telefone.replace(/\D/g, '');

    const footer_color = '#334C46'

    return (
        <div style={{ background: '#E6E6E6' }}>

            <div style={header}>
                <img class="guandu_logo" src={logoguandu} />
            </div>

            <div className="guandu_compre">
                <h2>Compre direto de quem produz!</h2>
                <a href="https://encurtagro.com/redeguandu" target="_blank">
                    <button className="guandu_button">Faça seu pedido</button>
                </a>
            </div>

            <div className="guandu_quem">
                <h2>Quem somos?</h2>
                <div className="guandu_quem2">
                    <div>
                        <h3>Guandu surgiu com o objetivo de solucionar dois problemas: dificuldade dos agricultores e produtores de Piracicaba e Americana em comercializar seus produtos pelos canais tradicionais de venda e a falta de acesso aos produtos de origem agroecológica.
                        </h3><h3>A Rede não tem fins lucrativos e é caracterizada como um Grupo de Consumo Responsável
                            (GCR), funcionando seguindo os princípios da economia solidária, a partir da auto-gestão, promoção da relação direta entre consumidores e produtores e comércio justo.</h3>
                    </div>
                    <img src={quem1} />
                    <img src={quem2} />
                    <img src={quem3} />
                </div>
            </div>

            <div className="guandu_container1">
                <div className="guandu_rounds_div">
                    <h2>Missão</h2>
                    <img style={rounds} src={round1} />
                    <h3>Encurtar a cadeia de produção</h3>
                </div>

                <div className="guandu_rounds_div">
                    <h2>Visão</h2>
                    <img style={rounds} src={round2} />
                    <h3>Unir cada vez mais os produtores aos consumidores</h3>
                </div>

                <div className="guandu_rounds_div">
                    <h2>Valores</h2>
                    <img style={rounds} src={round3} />
                    <h3>Fortalecimento da agricultura sustentavel </h3>
                </div>
            </div>

            <div className="guandu_como">
                <img src={feijaoguandu} />
                <h2>Como funciona a compra coletiva?</h2>
            </div>

            <div className="guandu_container2" style={{ background: '#F2F0C6' }}>
                <div className="guandu_div3">
                    <FaBasketShopping style={{ fontSize: 170, color: footer_color }} />
                    <h3>Os pedidos podem ser feitos de quarta a domingo pelo site ou app</h3>
                </div>
                <div className="guandu_div4">
                    <RiEBike2Line style={{ fontSize: 170, color: footer_color }} />
                    <h3>RETIRADA PRESENCIAL TERÇA-FEIRA DAS 17:00-20:00 (ESALQ)
                        <div style={{ marginTop: 10 }}>
                            DELIVERY DE CESTAS, TERÇA-FEIRA DAS 18:00-20:00
                        </div>
                    </h3>
                </div>
            </div>

            <div className="guandu_produtores">
                <h2>Produtores</h2>

                <div className="guandu_produtores2">
                    <div className="guandu_produtores3">
                        <img src={betins} style={produtores_logo} />
                        <h3>Betin's</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={cabocla} style={produtores_logo} />
                        <h3>Cabocla da amazônia</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={granola} style={produtores_logo} />
                        <h3>Elemento natural</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={vegana} style={produtores_logo} />
                        <h3>Doce vida vegana</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={redondo} style={produtores_logo} />
                        <h3>Brownie do redondo</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={cogumelo} style={produtores_logo} />
                        <h3>Cogumelos moushi</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={copaiba} style={produtores_logo} />
                        <h3>Espaço copaíba</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={expansao} style={produtores_logo} />
                        <h3>Expansão agroflorestal</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={gourmet} style={produtores_logo} />
                        <h3>Gourmet do bem</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={sattva} style={produtores_logo} />
                        <h3>Sattva</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={trigallia} style={produtores_logo} />
                        <h3>Trigallia</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={huertita} style={produtores_logo} />
                        <h3>La huertita</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={catarina} style={produtores_logo} />
                        <h3>La catarina</h3>
                    </div>

                    <div className="guandu_produtores3">
                        <img src={komfuzza} style={produtores_logo} />
                        <h3>Komfuzza</h3>
                    </div>

                </div>
            </div>

            <div className="guandu_footer">
                <div>
                    <h2>Quer fazer parte do nosso grupo</h2>
                    <h2>de produtores?</h2>

                    <div className="guandu_footer2">
                        <div style={{ marginRight: 20 }}>
                            <h2>Entre em contato:</h2>
                        </div>

                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <HiOutlineMail style={{ color: '#000', fontSize: 30 }} />
                            <span style={contato}>redeguandu@gmail.com</span>
                        </div>

                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                            <FaInstagram style={{ color: '#000', fontSize: 30 }} />
                            <span style={contato}>@Redeguandu</span>
                        </div>
                    </div>
                </div>

                <img src={footer} style={{ width: 300 }} />

            </div>
        </div>
    );
}
