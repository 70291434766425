import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Server from '../Servidor';
import { useDados } from "../context/Dados";
import { FiTruck, FiMapPin } from "react-icons/fi";
import { FaPix, FaCreditCard } from "react-icons/fa6";
import AddCartao from "./AddCartao";

export default function ContinuarCompra({ fechar, sacola, dadosforn, total, login, finalizado }) {

    var { dadosmain } = useDados()
    const dados = dadosmain[0].dados

    const amarelo1 = '#ffba08'
    const amarelo2 = '#e09f3e'
    const verde = '#65b341'
    const rgb = 'rgba(255, 255, 255, 0.1)'

    const entrega = dadosforn?.extensoes?.delivery?.entrega?.search('1') > -1 ? true : false
    const retirada = dadosforn?.extensoes?.delivery?.entrega?.search('2') > -1 ? true : false

    const [display_continuar1, set_display_continuar1] = useState((entrega || retirada) ? true : false)
    const [display_continuar_semextensao, set_display_continuar_semextensao] = useState((entrega || retirada) ? false : true)

    const [display_continuar_entrega, set_display_continuar_entrega] = useState(false)
    const [display_continuar_retirada, set_display_continuar_retirada] = useState(false)
    const [cupominput, setcupominput] = useState('')
    const [display_cupom, set_display_cupom] = useState(false)
    const [display_pagamento, set_display_pagamento] = useState(false)
    const [display_load, set_display_load] = useState(false)
    const [display_finalizado, set_display_finalizado] = useState(false)
    const [pagamento, setpagamento] = useState(false)
    const [tipo_entrega, set_tipo_entrega] = useState(false)
    const [qrcode, set_qrcode] = useState(false)

    const [display_cartoes, set_display_cartoes] = useState(false)
    const [display_addcartao, set_display_addcartao] = useState(false)
    const [cartoes, setcartoes] = useState([])
    const [cartao, setcartao] = useState(false)

    var taxa_entrega = 0
    const [cupom, setcupom] = useState(false)

    if (dadosforn.extensoes?.delivery?.taxafixa || dadosforn.extensoes?.delivery?.taxavariavel) {
        if (dadosforn.extensoes?.delivery?.pedidoentregagratis) {
            if (parseFloat(total) >= parseFloat(dadosforn.extensoes?.delivery?.pedidoentregagratis)) {
                taxa_entrega = 0
            } else {
                if (dadosforn.extensoes?.delivery?.taxafixa) {
                    taxa_entrega = dadosforn.extensoes?.delivery?.taxafixa
                } else {
                    var lat2 = dados.latitude;
                    var lon2 = dados.longitude;
                    var lat1 = dadosforn.extensoes?.delivery?.latitude;
                    var lon1 = dadosforn.extensoes?.delivery?.longitude;

                    var R = 6371; // km 
                    //has a problem with the .toRad() method below.
                    var x1 = lat2 - lat1;

                    function toRad(Value) {
                        /** Converts numeric degrees to radians */
                        return Value * Math.PI / 180;
                    }

                    var dLat = toRad(x1);
                    var x2 = lon2 - lon1;
                    var dLon = toRad(x2);
                    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    const taxapreco = (parseFloat(d) * parseFloat(dadosforn.extensoes?.delivery?.taxavariavel)).toFixed((parseFloat(d) * parseFloat(dadosforn.extensoes?.delivery?.taxavariavel)) % 1 == 0 ? 0 : 2)

                    var newtaxapreco = taxapreco
                    if (d < dadosforn.extensoes?.delivery?.kmgratis) {
                        newtaxapreco = 0
                    }

                    if (dadosforn.extensoes?.delivery?.taxavariavelinicial) {
                        newtaxapreco = parseFloat(newtaxapreco) + parseFloat(dadosforn.extensoes?.delivery?.taxavariavelinicial)
                    }

                    taxa_entrega = ((parseFloat(total) * parseFloat(newtaxapreco)) / 100).toFixed(2)
                }
            }
        }
    }


    function VerificarPedidoMinimo(valor) {
        set_tipo_entrega(valor)
        if (dadosforn.compras) {
            alert('Período de compras fechado!')
        } else {
            if (valor == 'entrega') {
                if (dadosforn.extensoes?.delivery?.pedidominimoentrega) {
                    if (parseFloat(total) < parseFloat(dadosforn.extensoes?.delivery?.pedidominimoentrega)) {
                        alert('Pedido mínimo para entrega: R$ ' + dadosforn.extensoes?.delivery?.pedidominimoentrega)
                    } else {
                        VerificarLogin(valor)
                    }
                } else {
                    VerificarLogin(valor)
                }
            }
            if (valor == 'retirada') {
                if (dadosforn.extensoes?.delivery?.pedidominimoretirada) {
                    if (parseFloat(total) < parseFloat(dadosforn.extensoes?.delivery?.pedidominimoretirada)) {
                        alert('Pedido mínimo para entrega: R$ ' + dadosforn.extensoes?.delivery?.pedidominimoretirada)
                    } else {
                        VerificarLogin(valor)
                    }
                } else {
                    VerificarLogin(valor)
                }
            }
        }
    }

    function VerificarLogin(valor) {
        if (dados) {
            if (valor == 'entrega') {
                set_display_continuar1(false)
                set_display_continuar_entrega(true)
            }
            if (valor == 'retirada') {
                set_display_continuar1(false)
                set_display_continuar_retirada(true)
            }
            if (valor == 'sem_extensao') {
                set_display_continuar_semextensao(false)
                set_display_pagamento(true)
            }
        } else {
            login()
        }
    }

    function BuscarCupom() {
        const caminho = dadosforn.extensoes.cupons[cupominput]
        if (caminho) {
            if (caminho.situacao == 1) {
                if (caminho.tipo == 'Porcentagem') {
                    setcupom({ valor: ((parseFloat(total) * parseFloat(caminho.valor)) / 100).toFixed(2), codigo: cupominput })
                    set_display_cupom(true)
                } else {
                    setcupom({ valor: parseFloat(caminho.valor), codigo: cupominput })
                    set_display_cupom(true)
                }
            } else {
                alert('Cupom não encontrado!')
                setcupom(false)
                set_display_cupom(false)
            }
        } else {
            alert('Cupom não encontrado!')
            setcupom(false)
            set_display_cupom(false)
        }
    }

    async function FinalizarCompra() {

        const newsacola = []
        for (let i = 0; i < sacola.length; i++) {
            const infos = sacola[i].infos
            const tamanho = sacola[i].tamanho

            newsacola.push({
                id: infos.id.substring(11),
                idlocal: infos.idlocal,
                categoria: infos.categoria,
                nomeforn: infos.nomeforn,
                userforn: infos.userforn,
                quantidade: sacola[i].qtd,
                minimopromo: tamanho.minimopromo,
                precopromo: tamanho.precopromo,
                preco: tamanho.preco,
                produtorid: infos.produtorid,
                produtornome: infos.produtornome,
                icone: infos.icone,
                titulo: infos.titulo,
                tamanho: tamanho.tamanho,
                opcaoselecionada: sacola[i].opcao
            })
        }

        set_display_pagamento(false)
        set_display_cartoes(false)
        set_display_load(true)

        try {
            const resposta = await Server({
                codigo: 'finalizar_pedido',
                site: true,
                cartao: pagamento == 3 ? cartao : false,
                sacola: newsacola,
                forn: dadosforn,
                dados,
                total,
                desconto: cupom ? cupom.valor : 0,
                taxaentregafinal: tipo_entrega == 'entrega' ? taxa_entrega : 0,
                pagamento,
                entrega: tipo_entrega == 'entrega' ? true : false,
                infoscupom: cupom ? cupom.codigo : null
            });

            if (resposta.error) {
                console.log(resposta.error)
                alert('Algum erro ocorreu!')
                set_display_load(false)
                set_display_pagamento(true)
            } else {
                console.log(resposta.all)

                if (resposta.all == 2) {
                    set_qrcode({ imagem_qrcode: resposta.all.imagem_qrcode, qrcode: resposta.all.qrcode })
                }
                finalizado()
                set_display_load(false)
                set_display_finalizado(true)
            }

        } catch (error) {
            console.log(error)
            alert('Algum erro ocorreu!')
            set_display_load(false)
            set_display_pagamento(true)
        }
    }

    function CardCartao({ item }) {

        return (
            <div className={`card_cartao_continuar_compra ${cartao.id == item.id ? 'card_cartao_continuar_compra_select' : ''}`} style={{ cursor: 'pointer', marginBottom: 5 }} onClick={() => setcartao(item)}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FaCreditCard className="icone_continuar_compra_pagamento" style={{ color: '#ff9e00', fontSize: '45px' }} />
                    <div style={{ marginLeft: '20px' }}>
                        <div style={{ fontSize: 18, color: '#fff' }}>{item.bandeira}</div>
                        <div style={{ fontSize: 18, color: '#fff' }}>{item.card_mask.substring(item.card_mask.length - 4)}</div>
                    </div>
                </div>
            </div>
        )
    }

    function voltar() {
        set_display_addcartao(false)
        set_display_cartoes(true)
    }

    function voltar_finalizado() {
        set_display_addcartao(false)
        PegarCartoes()
    }

    function voltar_pagamento() {
        set_display_pagamento(false)

        if (entrega || retirada) {
            if (tipo_entrega == 'entrega') {
                set_display_continuar_entrega(true)
            } else {
                set_display_continuar_retirada(true)
            }
        } else {
            set_display_continuar_semextensao(true)
        }
    }

    async function PegarCartoes() {
        set_display_pagamento(false)
        set_display_load(true)

        try {
            const resposta = await Server({ codigo: 'pegar_cartoes', usuario: dados.usuario });
            set_display_load(false)

            if (resposta.error) {
                console.log(resposta.error)
                set_display_pagamento(true)
                alert('Algum erro ocorreu!')
            } else {
                setcartoes(resposta.cartoes)
                set_display_cartoes(true)
            }

        } catch (error) {
            console.log(error)
            set_display_pagamento(true)
            alert('Algum erro ocorreu!')
        }
    }

    return (
        <div>
            {display_continuar1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {entrega ?
                            <div className="opcao_continuar_conpra" onClick={() => VerificarPedidoMinimo('entrega')}>
                                <FiTruck style={{ fontSize: 55, color: amarelo1 }} />
                                <span className="opcao_continuar_conpra_texto">Entrega</span>
                            </div>
                            : null}

                        {retirada ?
                            <div className="opcao_continuar_conpra" onClick={() => VerificarPedidoMinimo('retirada')}>
                                <FiMapPin style={{ fontSize: 55, color: verde }} />
                                <span className="opcao_continuar_conpra_texto">Retirada</span>
                            </div>
                            : null}
                    </div>
                </div>
                : null}

            {display_continuar_semextensao ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Produtos: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {parseFloat(total).toFixed(2)}</span>
                        </div>

                        {display_cupom ?
                            <div className="continuar_compra_valores">
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>Cupom: </span>
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>- R$ {cupom.valor}</span>
                            </div>
                            : null}

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Total: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {(parseFloat(total) + parseFloat(taxa_entrega) - (cupom ? parseFloat(cupom.valor) : 0)).toFixed(2)}</span>
                        </div>

                        {dadosforn.extensoes.cupons ?
                            <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', alignItems: 'center' }}>
                                <input value={cupominput} onChange={(text) => setcupominput(text.target.value.toUpperCase())} className="cupom_input" placeholder="Cupom"></input>
                                <span style={{ color: amarelo1, cursor: 'pointer', padding: 10 }} onClick={() => BuscarCupom()}>Aplicar</span>
                            </div>
                            : null}

                        <span onClick={() => VerificarLogin('sem_extensao')} className="btncontinuar" style={{ width: '60%', marginTop: 20 }}>Formas de pagamento</span>
                    </div>
                </div>
                : null}

            {display_continuar_entrega ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_continuar_entrega(false); set_display_continuar1(true) }} />

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <FiTruck style={{ fontSize: 45, color: amarelo1 }} />
                        <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius', marginTop: 10 }}>Receber em</span>
                        <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius', marginTop: 10, marginBottom: 10 }}>{dados.rua + ', ' + dados.numero + ', ' + dados.cidade}</span>

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Produtos: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {parseFloat(total).toFixed(2)}</span>
                        </div>

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Taxa de entrega: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {taxa_entrega}</span>
                        </div>

                        {display_cupom ?
                            <div className="continuar_compra_valores">
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>Cupom: </span>
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>- R$ {cupom.valor}</span>
                            </div>
                            : null}

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Total: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {(parseFloat(total) + parseFloat(taxa_entrega) - (cupom ? parseFloat(cupom.valor) : 0)).toFixed(2)}</span>
                        </div>

                        {dadosforn.extensoes.cupons ?
                            <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', alignItems: 'center' }}>
                                <input value={cupominput} onChange={(text) => setcupominput(text.target.value.toUpperCase())} className="cupom_input" placeholder="Cupom"></input>
                                <span style={{ color: amarelo1, cursor: 'pointer', padding: 10 }} onClick={() => BuscarCupom()}>Aplicar</span>
                            </div>
                            : null}

                        <span onClick={() => { set_display_continuar_entrega(false); set_display_pagamento(true) }} className="btncontinuar" style={{ width: '60%', marginTop: 20 }}>Formas de pagamento</span>
                    </div>
                </div>
                : null}

            {display_continuar_retirada ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_continuar_retirada(false); set_display_continuar1(true) }} />

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <FiMapPin style={{ fontSize: 45, color: verde }} />
                        <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius', marginTop: 10, textAlign: 'center' }}>{dadosforn.extensoes?.delivery?.retiradaend}</span>
                        <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius', marginTop: 10, marginBottom: 10, textAlign: 'center' }}>{dadosforn.extensoes?.delivery?.horarioretirada}</span>

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Produtos: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {parseFloat(total).toFixed(2)}</span>
                        </div>

                        {display_cupom ?
                            <div className="continuar_compra_valores">
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>Cupom: </span>
                                <span style={{ color: amarelo1, fontSize: 17, fontFamily: 'Delius' }}>- R$ {cupom.valor}</span>
                            </div>
                            : null}

                        <div className="continuar_compra_valores">
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>Total: </span>
                            <span style={{ color: '#fff', fontSize: 17, fontFamily: 'Delius' }}>R$ {(parseFloat(total) - (cupom ? parseFloat(cupom.valor) : 0)).toFixed(2)}</span>
                        </div>

                        {dadosforn.extensoes.cupons ?
                            <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', alignItems: 'center' }}>
                                <input value={cupominput} onChange={(text) => setcupominput(text.target.value.toUpperCase())} className="cupom_input" placeholder="Cupom"></input>
                                <span style={{ color: amarelo1, cursor: 'pointer', padding: 10 }} onClick={() => BuscarCupom()}>Aplicar</span>
                            </div>
                            : null}

                        <span onClick={() => { set_display_continuar_retirada(false); set_display_pagamento(true) }} className="btncontinuar" style={{ width: '70%', marginTop: 20 }}>Formas de pagamento</span>
                    </div>
                </div>
                : null}

            {display_pagamento ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => voltar_pagamento()} />

                    {dadosforn.extensoes.pagamentos ?
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', paddingTop: '40px' }}>
                            {dadosforn.extensoes.pagamentos.opcoes.search('1') > -1 ?
                                <div className={`opcao_continuar_conpra ${pagamento == 1 ? 'opcao_continuar_conpra_select' : ''}`} onClick={() => setpagamento(1)}>
                                    <FiTruck className="icone_continuar_compra_pagamento" style={{ color: amarelo1 }} />
                                    <span className="opcao_continuar_conpra_texto">Pagar na entrega</span>
                                </div>
                                : null}

                            {dadosforn.extensoes.pagamentos.opcoes.search('2') > -1 ?
                                <div className={`opcao_continuar_conpra ${pagamento == 2 ? 'opcao_continuar_conpra_select' : ''}`} onClick={() => setpagamento(2)}>
                                    <FaPix className="icone_continuar_compra_pagamento" style={{ color: 'rgb(95, 185, 173)' }} />
                                    <span className="opcao_continuar_conpra_texto">Pagar agora (Pix)</span>
                                </div>
                                : null}

                            {dadosforn.extensoes.pagamentos.opcoes.search('2') > -1 ?
                                <div className={`opcao_continuar_conpra ${pagamento == 3 ? 'opcao_continuar_conpra_select' : ''}`} onClick={() => setpagamento(3)}>
                                    <FaCreditCard className="icone_continuar_compra_pagamento" style={{ color: '#ff9e00' }} />
                                    <span className="opcao_continuar_conpra_texto">Cartão de crédito</span>
                                </div>
                                : null}
                        </div>
                        :
                        <div className={`opcao_continuar_conpra ${pagamento ? 'opcao_continuar_conpra_select' : ''}`} onClick={() => setpagamento(1)}>
                            <FiTruck style={{ fontSize: 55, color: amarelo1 }} />
                            <span className="opcao_continuar_conpra_texto">Pagar na entrega</span>
                        </div>}

                    {pagamento ?
                        <span onClick={() => pagamento == 3 ? PegarCartoes() : FinalizarCompra()} className="btncontinuar" style={{ width: '60%', marginTop: 20 }}>{pagamento == 3 ? 'Selecionar cartão' : 'Finalizar Compra'}</span>
                        :
                        <span className="btncontinuar" style={{ width: '60%', marginTop: 20, background: '#999' }}>Finalizar Compra</span>
                    }
                </div>
                : null}

            {display_cartoes ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_cartoes(false); set_display_pagamento(true) }} />

                    {cartoes.length >= 3 ? null :
                        <span onClick={() => { set_display_cartoes(false); set_display_addcartao(true) }} style={{ textAlign: 'center', fontWeight: 600, color: '#fff', cursor: 'pointer', fontSize: '16px', padding: '16px', marginBottom: '15px' }}>Adicionar cartão</span>
                    }
                    <div className="modalconta_div">
                        {cartoes.map((item, index) => {
                            return <CardCartao key={index} item={item} />
                        })}

                        {cartao ?
                            <span onClick={() => FinalizarCompra()} className="btncontinuar" style={{ marginTop: '15px' }}>Finalizar Compra</span>
                            :
                            <span className="btncontinuar" style={{ marginTop: '15px', background: '#999' }}>Finalizar Compra</span>
                        }
                    </div>
                </div>
                : null}

            {display_addcartao ?
                <AddCartao
                    fechar={() => fechar()}
                    voltar={() => voltar()}
                    finalizado={() => voltar_finalizado()}
                />
                : null}

            {display_load ?
                <div className="fundoescuro2" />
                : null}

            {display_load ?
                <div className="modalconta">
                    <span className="btncontinuar_load" />
                </div>
                : null}

            {display_finalizado ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    {qrcode ?
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginTop: 20 }}>Pedido realizado com sucesso!</span>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', marginTop: 15, marginBottom: 15 }}>
                                <img style={{ width: 200, height: 200, marginBottom: 20 }} src={qrcode.imagem_qrcode} />
                                <div style={{ textAlign: 'center', marginBottom: 15 }}>
                                    <span style={{ fontSize: 16, color: '#fff', fontFamily: 'Delius', fontWeight: 500, wordBreak: 'break-all' }}>{qrcode.qrcode}</span>
                                </div>
                                <span onClick={() => fechar()} className="btncontinuar" style={{ width: '60%' }}>OK</span>
                            </div>
                        </div>
                        :
                        <div className="modalconta_div">
                            <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Pedido realizado com sucesso!</span>
                            <span onClick={() => fechar()} className="btncontinuar">OK</span>
                        </div>
                    }
                </div>
                : null}

        </div>
    );
}
