import { useState } from "react";
import Header from "./Header";

export default function Modelo1({ allprods, dadosforn }) {

    const allcats = []
    const allsubs = []

    //pegar as categorias
    const todascategorias = dadosforn.extensoes.categorias
    for (let id in todascategorias) {
        allcats.push(todascategorias[id])
    }
    //pegar os produtores
    const todosprodutores = dadosforn.extensoes.produtores
    for (let id in todosprodutores) {
        allsubs.push(todosprodutores[id].nome)
    }

    allcats.unshift('Todas');
    allsubs.unshift('Todos');

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Header
                dadosforn={dadosforn}
                allprods={allprods}
                allcats={allcats}
                allsubs={allsubs}
            />
        </div>
    );
}
