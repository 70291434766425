import { useState, useEffect } from "react";
import Server from './Servidor'
import Modelo1 from "./Modelo1/Modelo1";
import Modelo2 from "./Modelo2/Modelo2";
import { useDados } from './context/Dados';
import { useLocation } from 'react-router-dom';
import farmer from './img/farmer.svg';
import { FcSupport } from "react-icons/fc";

export default function Select() {

    const location = useLocation();
    const { pathname } = location;
    const urlforn = pathname.substring(1);

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const laranja = '#EB941D'

    function Paleta(paleta) {
        if (paleta == 2) {
            document.documentElement.style.setProperty('--verdeescuro1', '#23231A');
        }
        if (paleta == 3) {
            document.documentElement.style.setProperty('--verdeescuro1', '#7E2E3A');
            document.documentElement.style.setProperty('--verde1', '#C85737');
            document.documentElement.style.setProperty('--verde2', '#E87449');
        }
        if (paleta == 4) {
            document.documentElement.style.setProperty('--verdeescuro1', '#452A23');
            document.documentElement.style.setProperty('--verde1', '#805E4D');
            document.documentElement.style.setProperty('--verde2', '#AD8C6A');
        }
        if (paleta == 5) {
            document.documentElement.style.setProperty('--verdeescuro1', '#193245');
            document.documentElement.style.setProperty('--verde1', '#2C7872');
            document.documentElement.style.setProperty('--verde2', '#2B8988');
        }
    }

    const [naoexiste, setnaoexiste] = useState(false)
    const [manutencao, setmanutencao] = useState(false)
    const [modelo, setmodelo] = useState(false)
    const [dadosforn, setdadosforn] = useState([])
    const [allprods, setallprods] = useState([])

    async function Start() {

        try {
            const resposta = await Server({ codigo: 'start_site', urlforn });

            if (resposta.error) {
                console.log(resposta.error)
                alert('Algum erro ocorreu!')
            } else {
                if (resposta.manutencao) {
                    setmanutencao(true)
                } else {
                    if (resposta.exist == false) {
                        setnaoexiste(true)
                    } else {
                        Paleta(resposta.infos.paleta)
                        const storedDataString = localStorage.getItem("dados");
                        if (storedDataString) {
                            const storedData = JSON.parse(storedDataString);
                            const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

                            if (resposta2.error) {
                                localStorage.removeItem("dados");
                            } else {
                                const all = resposta2.userinfo
                                dadosmain[0].dados = all
                                setdadosforn(resposta.dadosforn)
                                setallprods(resposta.allprods)
                                setmodelo(resposta.infos.modelo)
                            }
                        } else {
                            setdadosforn(resposta.dadosforn)
                            setallprods(resposta.allprods)
                            setmodelo(resposta.infos.modelo)
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
            alert('Algum erro ocorreu!')
        }
    }

    useEffect(() => {
        Start()
    }, []);

    return (
        <div style={{ height: '100vh', width: '100vw', background: '#fff' }}>

            {!modelo && !naoexiste && !manutencao ?
                <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <span className="load_inicial" />
                </div>
                : null}

            {naoexiste ?
                <div className="naoexiste">
                    <img className="naoexiste_img" src={farmer} />
                    <h1 className="naoexiste_h1">Página não encontrada</h1>
                </div>
                : null}

            {manutencao ?
                <div className="naoexiste" style={{ background: '#333' }}>
                    <FcSupport style={{ fontSize: 100 }} />
                    <h1 className="naoexiste_h1" style={{ color: '#fff' }}>Em manutenção</h1>
                </div>
                : null}

            {modelo == 1 ?
                <Modelo1
                    allprods={allprods}
                    dadosforn={dadosforn}
                />
                : null}

            {modelo == 2 ?
                <Modelo2
                    allprods={allprods}
                    dadosforn={dadosforn}
                />
                : null}

        </div>
    );
}
