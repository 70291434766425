import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Server from '../Servidor';
import { useDados } from "../context/Dados";
import { FiUser } from "react-icons/fi";
import cidades from "../Locais/Cidades";
import { FaCreditCard } from "react-icons/fa6";
import { FiTrash } from "react-icons/fi";
import AddCartao from "./AddCartao";

export default function Perfil({ fechar }) {

    var { dadosmain } = useDados()
    const dados = dadosmain[0].dados

    const bege = '#CEC2A8'
    const amarelo = '#ffba08'

    const [display_perfil, set_display_perfil] = useState(true)
    const [display_sair, set_display_sair] = useState(false)
    const [display_dados, set_display_dados] = useState(false)
    const [display_endereco0, set_display_endereco0] = useState(false)
    const [display_endereco1, set_display_endereco1] = useState(false)
    const [display_endereco2, set_display_endereco2] = useState(false)
    const [display_endereco3, set_display_endereco3] = useState(false)
    const [display_load, set_display_load] = useState(false)
    const [display_pedidos1, set_display_pedidos1] = useState(false)
    const [display_pedidos2, set_display_pedidos2] = useState(false)
    const [display_cartoes, set_display_cartoes] = useState(false)
    const [display_salvo, set_display_salvo] = useState(false)
    const [display_cancelar1, set_display_cancelar1] = useState(false)
    const [display_cancelar2, set_display_cancelar2] = useState(false)
    const [display_excluir, set_display_excluir] = useState(false)
    const [display_addcartao, set_display_addcartao] = useState(false)

    const [nome, setnome] = useState(dados.nome)
    const [rua, setrua] = useState(dados.rua)
    const [numero, setnumero] = useState(dados.numero)
    const [complemento, setcomplemento] = useState(dados.complemento || '')
    const [telefone, settelefone] = useState(dados.telefone)
    const [cidadeinput, setcidadeinput] = useState(dados.cidade)
    const [cidade, setcidade] = useState(dados.cidade)
    const [drop, setdrop] = useState(false)
    const [loadbtn, setloadbtn] = useState(false)

    const [pedidos, setpedidos] = useState([])
    const [pedido, setpedido] = useState([])

    const [cartoes, setcartoes] = useState([])
    const [cartao, setcartao] = useState([])

    const sairstyle = {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 27,
        cursor: 'pointer',
        padding: 20,
        color: '#fff',
        fontSize: 19
    }

    const btnnao = {
        textAlign: 'center',
        padding: 16,
        borderRadius: 10,
        fontWeight: 600,
        color: '#fff',
        cursor: 'pointer',
        fontSize: 16
    }

    const btnsim = {
        marginLeft: 20,
        textAlign: 'center',
        padding: 16,
        borderRadius: 10,
        fontWeight: 600,
        background: '#D35400',
        color: '#fff',
        cursor: 'pointer',
        fontSize: 16
    }

    const info_text = {
        color: '#fff',
        fontFamily: 'Delius',
        fontSize: 15,
        alignSelf: 'center'
    }

    const info_text_cancelar = {
        color: '#fff',
        fontFamily: 'Delius',
        fontSize: 15,
        alignSelf: 'center',
        cursor: 'pointer',
        backgroundColor: '#e63946',
        padding: 10,
        borderRadius: 10,
        marginTop: 10
    }

    async function SalvarDados() {
        setloadbtn(true)

        const resposta = await Server({ codigo: 'alterar_nome', usuario: dados.usuario, nome })
        setloadbtn(false)

        if (resposta.error) {
            console.log(resposta.error);
            alert('Algum erro ocorreu!')
        } else {
            dados.nome = nome
            set_display_dados(false)
            set_display_salvo(true)
        }
    }

    async function Next() {
        if (display_endereco0) {
            set_display_endereco0(false)
            set_display_endereco1(true)
        }
        if (display_endereco1) {
            set_display_endereco1(false)
            set_display_endereco2(true)
        }
        if (display_endereco2) {
            if (rua.length < 1 || numero.length < 1) {
                alert('Preencha todos os campos!')
            } else {
                if (isNaN(numero)) {
                    alert('Insira um número válido')
                } else {
                    set_display_endereco2(false)
                    set_display_endereco3(true)
                }
            }
        }
        if (display_endereco3) {
            setloadbtn(true)
            //verifica se precisa gerar coordenadas dnv
            if (cidade != dados.cidade || rua != dados.rua || numero != dados.numero) {
                const resposta = await Server({ codigo: 'gerar_coordenadas', rua, numero, cidade })

                if (resposta.error) {
                    console.log(resposta.error)
                    alert('Algum erro ocorreu!')
                } else {
                    const att = await Server({
                        codigo: 'alterar_endereco', usuario: dados.usuario, rua, numero, cidade, telefone,
                        complemento: complemento.length > 0 ? complemento : null, latitude: resposta.latitude, longitude: resposta.longitude
                    })
                    setloadbtn(false)

                    if (att.error) {
                        console.log(att.error)
                        alert('Algum erro ocorreu!')
                    } else {
                        dados.rua = rua
                        dados.numero = numero
                        dados.complemento = complemento.length > 1 ? complemento : null
                        dados.telefone = telefone
                        dados.cidade = cidade
                        dados.latitude = resposta.latitude;
                        dados.longitude = resposta.longitude;

                        set_display_endereco3(false)
                        set_display_salvo(true)
                    }
                }
            } else {
                const att = await Server({
                    codigo: 'alterar_endereco', usuario: dados.usuario, rua, numero, cidade, telefone,
                    complemento: complemento.length > 0 ? complemento : null, latitude: dados.latitude, longitude: dados.longitude
                })
                setloadbtn(false)

                if (att.error) {
                    console.log(att.error)
                    alert('Algum erro ocorreu!')
                } else {
                    dados.rua = rua
                    dados.numero = numero
                    dados.complemento = complemento.length > 1 ? complemento : null
                    dados.telefone = telefone
                    dados.cidade = cidade

                    set_display_endereco3(false)
                    set_display_salvo(true)
                }
            }
        }
    }

    function Sair() {
        dadosmain.splice(0, dadosmain.length)
        dadosmain.push([])

        localStorage.removeItem("dados")
        fechar()
    }

    function HandleInput({ text }) {
        setcidadeinput(text.target.value)
        if (!drop) {
            setdrop(true)
        }
    }

    function HandleTelefone({ text }) {
        let valor = text.replace(/\D/g, '');

        // Adiciona os parênteses e traço conforme o tamanho do valor
        if (valor.length > 2) {
            valor = `(${valor.slice(0, 2)})${valor.slice(2)}`;
        }
        if (valor.length > 8) {
            valor = `${valor.slice(0, 9)}-${valor.slice(9)}`;
        }
        settelefone(valor)
    }

    async function PegarPedidos() {
        set_display_perfil(false)
        set_display_load(true)

        try {
            const resposta = await Server({ codigo: 'pegar_pedidos', dados });
            set_display_load(false)

            if (resposta.error) {
                console.log(resposta.error)
                set_display_perfil(true)
                alert('Algum erro ocorreu!')
            } else {
                setpedidos(resposta.pedidos)
                set_display_pedidos1(true)
            }

        } catch (error) {
            console.log(error)
            set_display_perfil(true)
            alert('Algum erro ocorreu!')
        }
    }

    async function PegarCartoes() {
        set_display_perfil(false)
        set_display_load(true)

        try {
            const resposta = await Server({ codigo: 'pegar_cartoes', usuario: dados.usuario });
            set_display_load(false)

            if (resposta.error) {
                console.log(resposta.error)
                set_display_perfil(true)
                alert('Algum erro ocorreu!')
            } else {
                setcartoes(resposta.cartoes)
                set_display_cartoes(true)
            }

        } catch (error) {
            console.log(error)
            set_display_perfil(true)
            alert('Algum erro ocorreu!')
        }
    }

    function CardPedido({ item }) {
        var situacao = item.Infos.situacao[0];

        var color = '#fff';
        var text = '';
        if (situacao == '0') {
            color = '#e63946';
            text = 'Cancelado';
        } else if (situacao == '1') {
            color = '#f77f00';
            text = 'Em andamento';
        } else if (situacao == '2') {
            color = '#25a244';
            text = 'Entregue';
        }

        async function MostrarPedido() {
            set_display_pedidos1(false)
            set_display_load(true)

            try {
                const resposta = await Server({ codigo: 'pegar_dadosforn', usuario: item.Infos.userforn });
                set_display_load(false)

                if (resposta.error) {
                    console.log(resposta.error)
                    set_display_pedidos1(true)
                    alert('Algum erro ocorreu!')
                } else {
                    const lista = []
                    for (let id in item.Produtos) {
                        lista.push(item.Produtos[id])
                    }
                    setpedido({ infos: item, produtos: lista, fornend: resposta.dadosforn })
                    set_display_pedidos2(true)
                }

            } catch (error) {
                console.log(error)
                set_display_pedidos1(true)
                alert('Algum erro ocorreu!')
            }
        }

        return (
            <div className="cardPedido" onClick={() => MostrarPedido()}>
                <div style={{ fontSize: 18, color: color }}>{text}</div>
                <div style={{ color: '#fff', fontSize: 18, marginTop: 5 }}>Pedido realizado em {item.Infos.horario_pedido}</div>
            </div>
        )
    }

    function CardCartao({ item }) {

        return (
            <div style={{ borderRadius: '10px', padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FaCreditCard className="icone_continuar_compra_pagamento" style={{ color: '#ff9e00', fontSize: '45px' }} />
                    <div style={{ marginLeft: '20px' }}>
                        <div style={{ fontSize: 18, color: '#fff' }}>{item.bandeira}</div>
                        <div style={{ fontSize: 18, color: '#fff' }}>{item.card_mask.substring(item.card_mask.length - 4)}</div>
                    </div>

                    <div onClick={() => { setcartao(item); set_display_cartoes(false); set_display_excluir(true) }} style={{ flex: 1, alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
                        <FiTrash style={{ color: '#fff', fontSize: '25px', padding: '10px', cursor: 'pointer' }} />
                    </div>
                </div>
            </div>
        )
    }

    async function ExcluirCartao() {
        set_display_excluir(false)
        set_display_load(true)

        try {
            const resposta = await Server({ codigo: 'excluir_cartao', id: cartao.id, usuario: dados.usuario });

            if (resposta.error) {
                console.log(resposta.error)
            } else {
                PegarCartoes()
            }

        } catch (error) {
            console.log(error)
        }
    }

    function InfosPedido() {
        const [att_pedido, set_att_pedido] = useState(0)
        const situacao = pedido.infos.Infos.situacao[0]
        const entrega = pedido.infos.Infos.tipo_entrega == 'Entrega' ? true : false
        const fornend = pedido.fornend

        const consultados = []
        const encontrado0 = consultados.find(consulta => consulta[pedido.infos.id] == 'expirado');
        const encontrado2 = consultados.find(consulta => consulta[pedido.infos.id] == 'pago');

        let aguardando_pix = false
        let pago_pix = false
        let expirado_pix = false
        let base64 = false
        let qrcode = false

        if (pedido.infos.Infos.qrcode) {
            if (pedido.infos.Infos.status_pix == 0) {
                expirado_pix = true
            }
            if (pedido.infos.Infos.status_pix == 1) {
                aguardando_pix = true
            }
            if (pedido.infos.Infos.status_pix == 2) {
                pago_pix = true
            }
            base64 = pedido.infos.Infos.imagem_qrcode
            qrcode = pedido.infos.Infos.qrcode

            if (pedido.infos.Infos.status_pix == 1) {
                if (encontrado0) {
                    aguardando_pix = false
                    expirado_pix = true
                } else {
                    if (encontrado2) {
                        aguardando_pix = false
                        pago_pix = true
                    } else {
                        ConsultarPagamento()
                    }
                }
            }
        }

        async function ConsultarPagamento() {
            const all = {
                txid: pedido.infos.Infos.txid,
                idpedido: pedido.infos.idpedido,
                userforn: pedido.infos.Infos.userforn,
                horario_pedido: pedido.infos.Infos.horario_pedido,
                cliente_user: dados.usuario
            }

            try {
                const resposta = await Server({ codigo: 'consultar_pagamento', all });

                if (resposta.pago) {
                    aguardando_pix = false
                    pago_pix = true

                    consultados.push({
                        [pedido.infos.id]: 'pago'
                    })
                    pedido.infos.Infos.status_pix = 2
                    set_att_pedido(att_pedido + 1)

                } else {
                    if (resposta.expirado) {
                        aguardando_pix = false
                        expirado_pix = true

                        consultados.push({
                            [pedido.infos.id]: 'expirado'
                        })
                        pedido.infos.Infos.status_pix = 0
                        set_att_pedido(att_pedido + 1)
                    }
                }
            } catch (error) {
                console.log(error)
                alert('Algum erro ocorreu!')
            }
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={info_text}>Pedido realizado: {pedido.infos.Infos.horario_pedido}</span>

                {situacao == '0' ?
                    <span style={info_text}>Pedido cancelado: {pedido.infos.Infos.horario_cancelado}</span>
                    : null}

                {situacao == '2' ?
                    <span style={info_text}>Entrega realizada: {pedido.infos.Infos.horario_entregue}</span>
                    : null}

                {situacao === '0' ? null :
                    aguardando_pix ?
                        <div>
                            <p className="info_titulo_perfil" style={{ color: amarelo }}>Aguardando pagamento</p>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', marginTop: 5 }}>
                                <img style={{ width: 200, height: 200, marginBottom: 10 }} src={base64} />
                                <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                                    <span style={{ fontSize: 16, color: '#fff', fontFamily: 'Delius', fontWeight: 500, wordBreak: 'break-all' }}>{qrcode}</span>
                                </div>
                            </div>
                        </div>
                        :
                        pago_pix ?
                            <p className="info_titulo_perfil" style={{ color: '#65b341' }}>Pagamento aprovado!</p>
                            :
                            expirado_pix ?
                                <p className="info_titulo_perfil" style={{ color: '#E16046' }}>Qrcode expirado</p>
                                :
                                null
                }


                <p className="info_titulo_perfil" style={{ color: amarelo }}>{fornend?.nomeforn || 'Conta excluída'}</p>

                {entrega ?
                    <p style={info_text}>{pedido.infos.Infos.rua}, {pedido.infos.Infos.numero} - {pedido.infos.Infos.cidade}</p>
                    :
                    <p style={info_text}>Retirada no local</p>}

                {pedido.produtos.map((prod, index) =>
                    <div key={index} style={{ marginBottom: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '100%' }}>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginLeft: 10, paddingTop: 5, paddingBottom: 5, borderBottom: '2px solid #000' }}>
                                <p style={{ fontSize: 17, marginBottom: 3, marginTop: 0, color: bege }}>{prod.titulo}</p>
                                <p style={{ fontSize: 17, marginBottom: 3, marginTop: 0, color: bege }}>{prod.tamanho} x{prod.quantidade}</p>
                                {prod.opcao ?
                                    <p style={{ fontSize: 17, marginBottom: 3, marginTop: 0, color: bege }}>{prod.opcao}</p>
                                    : null}
                                <p style={{ fontSize: 17, marginBottom: 3, marginTop: 0, color: bege }}>R$ {prod.pago}</p>
                            </div>

                        </div>
                    </div>
                )}

                {situacao == '1' && fornend ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p onClick={() => { set_display_pedidos2(false); set_display_cancelar1(true) }} style={info_text_cancelar}>Cancelar pedido</p>
                    </div>
                    : null}

            </div>
        )
    }

    async function Cancelar() {
        set_display_cancelar1(false)
        set_display_load(true)

        try {
            const resposta = await Server({ codigo: 'cancelar_pedido', usuario: dados.usuario, item: pedido.infos, fornend: pedido.fornend });
            set_display_load(false)

            if (resposta.error) {
                console.log(resposta.error)
                alert('Algum erro ocorreu!')
                set_display_pedidos2(true)
            } else {
                set_display_cancelar2(true)
            }

        } catch (error) {
            console.log(error)
            alert('Algum erro ocorreu!')
            set_display_pedidos2(true)
        }
    }

    function voltar() {
        set_display_addcartao(false)
        set_display_cartoes(true)
    }

    function voltar_finalizado() {
        set_display_addcartao(false)
        PegarCartoes()
    }

    return (
        <div>
            {display_perfil ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <span onClick={() => { set_display_perfil(false); set_display_dados(true) }} className="modal_conta_entrar_criar">Dados pessoais</span>
                    <span onClick={() => { set_display_perfil(false); set_display_endereco0(true) }} className="modal_conta_entrar_criar">Endereço</span>
                    <span onClick={() => PegarPedidos()} className="modal_conta_entrar_criar">Pedidos</span>
                    <span onClick={() => PegarCartoes()} className="modal_conta_entrar_criar">Cartões</span>

                    <span style={sairstyle} onClick={() => { set_display_perfil(false); set_display_sair(true) }}>Sair</span>

                </div>
                : null}

            {display_sair ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_sair(false); set_display_perfil(true) }} />

                    <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Sair da sua conta?</span>

                    <div className="modalconta_div" style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <span onClick={() => { set_display_sair(false); set_display_perfil(true) }} style={btnnao}>Não</span>
                        <span onClick={() => Sair()} style={btnsim}>Sim</span>
                    </div>

                </div>
                : null}

            {display_dados ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_dados(false); set_display_perfil(true) }} />

                    <div className="modalconta_div">
                        <span className="modal_conta_titulos">Email</span>
                        <span className="modal_conta_titulos" style={{ marginTop: 10 }}>{dados.email}</span>

                        <span className="modal_conta_titulos" style={{ marginTop: 30 }}>Nome</span>
                        <input value={nome} onChange={(text) => setnome(text.target.value)} className="modal_conta_inputs" placeholder="Nome"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => SalvarDados()} className="btncontinuar">Salvar</span>
                        }
                    </div>
                </div>
                : null}

            {display_salvo ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div className="modalconta_div">
                        <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Salvo com sucesso!</span>
                        <span onClick={() => fechar()} className="btncontinuar">OK</span>
                    </div>
                </div>
                : null}

            {display_endereco0 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_endereco0(false); set_display_perfil(true) }} />

                    <div className="modalconta_div_end0">

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <span className="modal_conta_titulos">Cidade</span>
                            <span className="modal_conta_endereco0" style={{ marginTop: 10 }}>{dados.cidade}</span>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <span className="modal_conta_titulos">Rua</span>
                                <span className="modal_conta_endereco0" style={{ marginTop: 10 }}>{dados.rua}</span>
                            </div>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <span className="modal_conta_titulos">Número</span>
                                <span className="modal_conta_endereco0" style={{ marginTop: 10 }}>{dados.numero}</span>
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <span className="modal_conta_titulos">Complemento</span>
                                <span className="modal_conta_endereco0" style={{ marginTop: 10 }}>{dados.complemento}</span>
                            </div>

                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <span className="modal_conta_titulos">Telefone</span>
                                <span className="modal_conta_endereco0" style={{ marginTop: 10 }}>{dados.telefone}</span>
                            </div>

                        </div>

                        <span onClick={() => Next()} className="btncontinuar">Editar</span>
                    </div>
                </div>
                : null}

            {display_endereco1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_endereco1(false); set_display_perfil(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Selecione sua cidade</span>
                        <input value={cidadeinput} onChange={(text) => HandleInput({ text })} className="modal_conta_inputs" placeholder="Cidade"></input>

                        {drop ?
                            <div className="dropdown_cidades">
                                <div className="dropdown_scroll">
                                    {cidades
                                        .filter(cidade => cidade.toLowerCase().includes(cidadeinput.toLowerCase()))
                                        .map((cidade, index) => (
                                            <span onClick={() => { setcidade(cidade); setcidadeinput(cidade); setdrop(false) }} key={index} className="dropdown_texto">{cidade}</span>
                                        ))}
                                </div>
                            </div>
                            : null}

                        {cidade && !drop ?
                            <span onClick={() => Next()} className="btncontinuar">Continuar</span>
                            : null}
                    </div>
                </div>
                : null}

            {display_endereco2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_endereco2(false); set_display_endereco1(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Rua</span>
                        <input value={rua} onChange={(text) => setrua(text.target.value)} className="modal_conta_inputs" placeholder="Rua"></input>

                        <span className="modal_conta_titulos">Número</span>
                        <input value={numero} onChange={(text) => setnumero(text.target.value)} className="modal_conta_inputs" placeholder="Número"></input>

                        <span onClick={() => Next()} className="btncontinuar">Continuar</span>

                    </div>
                </div>
                : null}

            {display_endereco3 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_endereco3(false); set_display_endereco2(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Complemento (opcional)</span>
                        <input value={complemento} onChange={(text) => setcomplemento(text.target.value)} className="modal_conta_inputs" placeholder="Complemento"></input>

                        <span className="modal_conta_titulos">Telefone</span>
                        <input maxLength={14} value={telefone} onChange={(text) => HandleTelefone({ text: text.target.value })} className="modal_conta_inputs" placeholder="(xx)xxxxx-xxxx"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => Next()} className="btncontinuar">Salvar</span>
                        }

                    </div>
                </div>
                : null}

            {display_load ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <span className="btncontinuar_load" />
                </div>
                : null}

            {display_pedidos1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_pedidos1(false); set_display_perfil(true) }} />

                    <div className="scroll">
                        {pedidos.map((item, index) => {
                            return <CardPedido key={index} item={item} />
                        })}
                    </div>
                </div>
                : null}

            {display_pedidos2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_pedidos2(false); set_display_pedidos1(true) }} />

                    <div className="scroll">
                        <InfosPedido />
                    </div>
                </div>
                : null}

            {display_cancelar1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_cancelar1(false); set_display_pedidos2(true) }} />

                    <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Cancelar pedido?</span>

                    <div className="modalconta_div" style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <span onClick={() => { set_display_cancelar1(false); set_display_pedidos2(true) }} style={btnnao}>Não</span>
                        <span onClick={() => Cancelar()} style={btnsim}>Sim</span>
                    </div>

                </div>
                : null}

            {display_cancelar2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div className="modalconta_div">
                        <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Pedido cancelado com sucesso!</span>
                        <span onClick={() => fechar()} className="btncontinuar">OK</span>
                    </div>
                </div>
                : null}

            {display_cartoes ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_cartoes(false); set_display_perfil(true) }} />

                    <div className="modalconta_div">
                        {cartoes.map((item, index) => {
                            return <CardCartao key={index} item={item} />
                        })}

                        {cartoes.length >= 3 ? null :
                            <span onClick={() => { set_display_cartoes(false); set_display_addcartao(true) }} className="btncontinuar" style={{ marginTop: '15px' }}>Adicionar cartão</span>
                        }
                    </div>
                </div>
                : null}

            {display_excluir ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_excluir(false); set_display_cartoes(true) }} />

                    <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Excluir cartão?</span>

                    <div className="modalconta_div" style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <span onClick={() => { set_display_excluir(false); set_display_cartoes(true) }} style={btnnao}>Não</span>
                        <span onClick={() => ExcluirCartao()} style={btnsim}>Sim</span>
                    </div>

                </div>
                : null}

            {display_addcartao ?
                <AddCartao
                    fechar={() => fechar()}
                    voltar={() => voltar()}
                    finalizado={() => voltar_finalizado()}
                />
                : null}

        </div>
    );
}
