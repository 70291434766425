import { useState } from "react";
import Header from "./Header";

export default function Modelo2({ allprods, dadosforn }) {

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Header
                dadosforn={dadosforn}
                allprods={allprods}
            />
        </div>
    );
}
