import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Server from '../Servidor';
import { useDados } from "../context/Dados";

export default function EntrarConta({ voltar, fechar }) {

    var { dadosmain } = useDados()

    const [display_entrar1, set_display_entrar1] = useState(true)
    const [display_entrar2, set_display_entrar2] = useState(false)
    const [display_esqueceu1, set_display_esqueceu1] = useState(false)
    const [display_esqueceu2, set_display_esqueceu2] = useState(false)

    const [email, setemail] = useState('')
    const [senha, setsenha] = useState('')
    const [loadbtn, setloadbtn] = useState(false)
    const [emailforgot, setemailforgot] = useState('')

    async function Entrar() {
        var re = /\S+@\S+\.\S+/;
        const emailtrim = email.trim().toLowerCase()
        const teste = re.test(emailtrim);
        if (email.length == 0 || senha.length < 6 || !teste) {
            alert('Algum erro ocorreu!')
        } else {
            setloadbtn(true)

            try {
                const resposta = await Server({ codigo: 'login', email: emailtrim, senha });
                setloadbtn(false)

                if (resposta.error) {
                    console.log(resposta.error)
                    if (resposta.error == 'Firebase: Error (auth/wrong-password).') {
                        alert('Senha incorreta!')
                    } else if (resposta.error == 'Firebase: Error (auth/user-not-found).') {
                        alert('Usuário não encontrado!')
                    } else {
                        alert('Algum erro ocorreu!')
                    }
                } else {
                    const all = resposta.userinfo
                    if (all.type) {
                        alert('Não é possível fazer login no site com conta de fornecedor!')
                    } else {
                        const dadosasync = {
                            login: emailtrim,
                            senha: senha
                        }
                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);

                        dadosmain[0].dados = all
                        set_display_entrar1(false)
                        set_display_entrar2(true)
                    }
                }

            } catch (error) {
                console.log(error)
                alert('Algum erro ocorreu!')
            }
        }
    }

    async function Forgot() {
        var re = /\S+@\S+\.\S+/;
        const emailtrim = emailforgot.trim().toLowerCase()
        const teste = re.test(emailtrim);
        if (emailforgot.length == 0 || !teste) {
            alert('Algum erro ocorreu!')
        } else {
            setloadbtn(true)
            try {
                const resposta = await Server({ codigo: 'forgot', email: emailtrim });
                setloadbtn(false)

                if (resposta.error) {
                    console.log(resposta.error);
                    alert('Algum erro ocorreu!')
                } else {
                    set_display_esqueceu1(false)
                    set_display_esqueceu2(true)
                }
            } catch (error) {
                console.log(error);
                alert('Algum erro ocorreu!')
            }
        }
    }

    return (
        <div>
            {display_entrar1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => voltar()} />

                    <div className="modalconta_div">
                        <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>Email</span>
                        <input value={email} onChange={(text) => setemail(text.target.value)} className="modal_conta_inputs" placeholder="Email"></input>

                        <span className="modal_conta_titulos">Senha</span>
                        <input type="password" value={senha} onChange={(text) => setsenha(text.target.value)} className="modal_conta_inputs" placeholder="Senha"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => Entrar()} className="btncontinuar">Entrar</span>
                        }
                        <span onClick={() => { set_display_entrar1(false); set_display_esqueceu1(true) }} style={{ textAlign: 'center', marginTop: 20, cursor: 'pointer', padding: 10, color: '#fff', fontFamily: 'Delius', fontWeight: 800, fontSize: 15 }}>Esqueceu a senha?</span>
                    </div>
                </div>
                : null}

            {display_entrar2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div className="modalconta_div">
                        <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Bem Vindo!</span>
                        <span onClick={() => fechar()} className="btncontinuar">OK</span>
                    </div>
                </div>
                : null}

            {display_esqueceu1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_esqueceu1(false); set_display_entrar1(true) }} />

                    <div className="modalconta_div">
                        <span className="modal_conta_titulos">Email</span>
                        <input value={emailforgot} onChange={(text) => setemailforgot(text.target.value)} className="modal_conta_inputs" placeholder="Email"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => Forgot()} className="btncontinuar">Enviar</span>
                        }
                    </div>
                </div>
                : null}

            {display_esqueceu2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div className="modalconta_div">
                        <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Email enviado com sucesso!</span>
                        <span onClick={() => fechar()} className="btncontinuar">OK</span>
                    </div>
                </div>
                : null}

        </div>
    );
}
