import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Server from '../Servidor';
import { useDados } from "../context/Dados";

export default function AddCartao({ voltar, fechar, finalizado }) {

    var { dadosmain } = useDados()
    const dados = dadosmain[0].dados

    const [display_load, set_display_load] = useState(false)
    const [display_addcartao1, set_display_addcartao1] = useState(true)

    const [numeroinput, setnumeroinput] = useState('')
    const [validadeinput, setvalidadeinput] = useState('')
    const [cvvinput, setcvvinput] = useState('')
    const [nomeinput, setnomeinput] = useState('')
    const [cpfinput, setcpfinput] = useState('')

    const formatCardNumber = (value) => {
        // Remover qualquer caractere não numérico do valor
        const cleanedValue = value.replace(/\D/g, '');

        // Adicionar um espaço a cada 4 dígitos
        let formattedValue = '';
        for (let i = 0; i < cleanedValue.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedValue += ' ';
            }
            formattedValue += cleanedValue[i];
        }

        return formattedValue;
    };

    const AlterarNumero = (number) => {
        const formattedNumber = formatCardNumber(number);
        setnumeroinput(formattedNumber);
    };

    const AlterarValidade = (value) => {
        const cleanedValue = value.replace(/\D/g, '');

        // Adicionar um '/' após os primeiros 2 dígitos
        let formattedValue = '';
        for (let i = 0; i < cleanedValue.length; i++) {
            if (i === 2) {
                formattedValue += '/';
            }
            formattedValue += cleanedValue[i];
        }

        setvalidadeinput(formattedValue);
    }

    function VerificarBandeira() {
        var cards = {
            visa: /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard: /^5[1-5][0-9]{14}/,
            amex: /^3[47][0-9]{13}/,
            hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        };

        var cardnumber = numeroinput.replace(/[^0-9]+/g, '');

        for (var flag in cards) {
            if (cards[flag].test(cardnumber)) {
                return flag;
            }
        }

        return false
    }

    function Verificar() {
        var bandeira = VerificarBandeira()
        if (bandeira) {
            if (validadeinput.length != 5) {
                alert('validade inválida')
            } else {
                if (cvvinput.length != 3) {
                    alert('CVV inválido')
                } else {
                    if (nomeinput.length < 5) {
                        alert('Digite o nome completo')
                    } else {
                        if (cpfinput.length != 11 && cpfinput.length != 14) {
                            alert('CPF/CNPJ inválido')
                        } else {
                            Salvar()
                        }
                    }
                }
            }
        } else {
            alert('Número do cartão inválido')
        }
    }

    async function Salvar() {
        try {
            set_display_addcartao1(false)
            set_display_load(true)

            const infos = {
                numero: numeroinput.trim(),
                validade: validadeinput,
                cvv: cvvinput,
                cpf: cpfinput,
                nome: nomeinput
            }

            const infos_teste = {
                numero: '4485785674290087',
                cvv: '123',
                validade: '05/29',
                nome: 'Gorbadoc Oldbuck',
                cpf: '94271564656'
            }

            const resposta = await Server({ codigo: 'adicionar_cartao', infos: infos, usuario: dados.usuario });

            if (resposta.error) {
                console.log(resposta.error)
                set_display_load(false)
                set_display_addcartao1(true)
                alert('Algum erro ocorreu!')
            } else {
                set_display_load(false)
                finalizado()
            }

        } catch (error) {
            console.log(error)
            set_display_load(false)
            set_display_addcartao1(true)
            alert('Algum erro ocorreu!')
        }
    }

    return (
        <div>
            {display_load ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <span className="btncontinuar_load" />
                </div>
                : null}

            {display_addcartao1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => voltar()} />

                    <div className="modalconta_div">
                        {/* <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>Número do cartão</span> */}
                        <input maxLength={19} value={numeroinput} onChange={(text) => AlterarNumero(text.target.value)} className="modal_conta_inputs" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0, marginBottom: '10px' }} placeholder="Número"></input>

                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1 }}>
                                <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>Validade</span>
                            </div>
                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>CVV</span>
                            </div>
                        </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1 }}>
                                <input maxLength={5} value={validadeinput} onChange={(text) => AlterarValidade(text.target.value)} className="modal_conta_inputs" style={{ marginBottom: '10px' }} placeholder="Validade"></input>
                            </div>
                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                <input maxLength={3} value={cvvinput} onChange={(text) => setcvvinput(text.target.value.replace(/\D/g, ''))} className="modal_conta_inputs" style={{ marginBottom: '10px' }} placeholder="CVV"></input>
                            </div>
                        </div>

                        {/* <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>Nome do titular</span> */}
                        <input maxLength={30} value={nomeinput} onChange={(text) => setnomeinput(text.target.value)} className="modal_conta_inputs" style={{ marginBottom: '10px' }} placeholder="Nome completo"></input>

                        {/* <span className="modal_conta_titulos" style={{ marginTop: window.innerWidth <= 860 ? 40 : 0 }}>CPF/CNPJ</span> */}
                        <input maxLength={14} value={cpfinput} onChange={(text) => setcpfinput(text.target.value.replace(/\D/g, ''))} className="modal_conta_inputs" style={{ marginBottom: '17px' }} placeholder="CPF/CNPJ"></input>

                        <span onClick={() => Verificar()} className="btncontinuar">Adicionar</span>
                    </div>
                </div>
                : null}

        </div>
    );
}
