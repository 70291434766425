import React from "react";
import { FcGallery } from "react-icons/fc";
import { CiImageOn } from "react-icons/ci";

const CardProduto = ({ item, detalhes }) => {
    const chocolate = '#D35400'

    return (
        item.status ? null :
            <div onClick={() => detalhes()} className="card_produto">
                {item.icone ? (
                    <img className="card_produto_img" src={item.icone} alt="Imagem do produto" />
                ) : (
                    <div style={{ width: '100%', height: 180, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <CiImageOn style={{ fontSize: 120 }} />
                    </div>
                )}
                <span className="card_produto_titulo">
                    {item.titulo}
                </span>
                <span style={{ fontSize: 18, fontFamily: 'PatrickHand', color: chocolate, display: 'block' }}>{item.tamanho}</span>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                    <span style={{ fontSize: 24, fontWeight: 700, fontFamily: 'PatrickHand', color: chocolate }}>R$ {item.precopromo ? item.precopromo : item.preco}</span>
                    {item.precopromo ?
                        <span style={{ marginLeft: 10, backgroundColor: '#55a630', color: '#fff', padding: 5, borderRadius: 5, fontWeight: 700, fontSize: 17 }}>{Math.round(100 - ((parseFloat(item.precopromo) * 100) / parseFloat(item.preco)))}%</span>
                        : null}
                </div>
            </div>
    )
}

export default React.memo(CardProduto)