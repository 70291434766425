import fundo from './img/fundo.png'
import playstore from './img/googleplay.png'
import appstore from './img/appstore.svg'
import logo from './img/logo.png'
import Footer from './Footer';

export default function App() {

  const verde = '#88d374'

  const divfundo = {
    height: '650px', flexDirection: 'column', backgroundImage: `url(${fundo})`, backgroundSize: 'cover',
    display: 'flex', alignItems: 'flex-start', justifyContent: 'center', paddingLeft: '80px'
  }

  const sobre = {
    display: 'flex',
    backgroundColor: verde,
    paddingTop: '20px',
    paddingBottom: '20px',
    flexWrap: 'wrap'
  }

  return (
    <div>
      <div style={divfundo}>

        <h1 className='titulo_pagina_inicial'>Encurtagro</h1>

        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.perseguidu.krl3">
          <img src={playstore} style={{ width: '119.66407px', height: '50px' }} />
        </a>
        <a target="_blank" href="https://apps.apple.com/us/app/encurtagro/id6443944425?itsct=apps_box_link&itscg=30200">
          <img src={appstore} />
        </a>

      </div>

      <div style={sobre}>
        <div className='sobre1'>
          <img src={logo} className='sobre1_logo' />
        </div>

        <div className='sobre2'>
          <h2 style={{ fontSize: '40px', fontFamily: 'PatrickHand', marginBottom: '0px', color: '#fff' }}>SOBRE</h2>
          <h3 style={{ color: '#fff', marginTop: '10px', fontSize: '23px', fontFamily: 'Delius' }}>A Encurtagro é uma startup de comercialização de produtos do agronegócio, que surgiu
            com o objetivo de encurtar a distância entre vendedor e comprador, facilitando todo o processo de compra
            e venda.</h3>
        </div>
      </div>

      <Footer />
    </div>
  );
}
