import { IoIosArrowBack } from "react-icons/io";
import Footer from "./Footer";

export default function Termos() {

    const verde = '#88d374'

    const contato = {
        fontSize: '20px', textAlign: 'center', fontFamily: 'Delius', color: '#fff', marginLeft: '10px'
    }

    const termos = {
        fontSize: '14px', textAlign: 'center', fontFamily: 'Delius', color: '#fff', marginRight: '10px', marginLeft: '10px'
    }

    return (
        <div>
            <a href="/">
                <IoIosArrowBack className="iconeback" />
            </a>

            <div className="termos1">
                <h1>TERMOS E CONDIÇÕES DE USO</h1>
            </div>
            <div className="termos2">
                <h2>1. SOBRE A PLATAFORMA ENCURTAGRO</h2>
                <h3>A Encurtagro é uma plataforma marketplace que atua nos diversos setores do agronegócio.
                    Visando regular o uso e acesso à plataforma, a Encurtagro disponibiliza os Temos e
                    Condições de Uso, doravante “Termos”, ao usuário que anunciará produtos dentro da
                    plataforma como “Usuário Vendedor”, ao usuário que realizará a compra dos produtos
                    como “Usuário Comprador” e a você usuário individual, que pode ser visitante da
                    plataforma, doravante também “Você”.</h3>
                <h2>2. ACESSO E USO DA PLATAFORMA</h2>
                <h3>Você, ao concluir o seu cadastro na plataforma, estará concordando automaticamente com
                    todas as disposições destes termos. Se Você não concordar com todo ou parte do conteúdo
                    dos Termos, ou de quaisquer alterações realizadas nestes, deverá imediatamente abster-se
                    de utilizar a plataforma. Em caso de dúvidas envie e-mail para contato@encurtagro.com.</h3>
                <h2>3. PRIVACIDADE E PROTEÇÃO DE DADOS</h2>
                <h3>A Encurtagro respeita sua privacidade e a proteção dos seus dados pessoais. Somente
                    utilizaremos seus dados pessoais, dentro dos limites legais e éticos, para atender às
                    finalidades pelas quais eles foram coletados. Para maiores informações sobre como
                    coletamos e utilizamos seus dados pessoais, bem como as medidas que adotamos para
                    protegê-los, acesse a nossa Política de Privacidade.</h3>
                <h2>4. MODIFICAÇÕES NOS TERMOS E CONDIÇÕES GERAIS</h2>
                <h3>O conteúdo destes termos poderá ser modificado a qualquer momento, e a nova versão
                    deste documento entrará em vigor a partir da sua publicação no site. Você somente será
                    comunicado sobre a alteração dos Termos se houver obrigação legal neste sentido. Por isso,
                    recomendamos que Você verifique periodicamente seu conteúdo para manter-se atualizado
                    sobre os mesmos. Caso não concorde com os novos Termos, num prazo máximo de 5 (cinco)
                    dias contados a partir da publicação no site da nova versão dos Termos, Você deverá
                    comunicar-se pelo e-mail contato@encurtagro.com manifestando formalmente as suas
                    discordâncias e interrompendo imediatamente o uso da plataforma. Não havendo
                    manifestação no prazo estipulado, entender-se-á que Você aceitou os novos Termos e
                    condições gerais de uso e o contrato continuará vinculando as partes, com base nos novos
                    Termos.</h3>
                <h2>5. PRIVACIDADE DA INFORMAÇÃO</h2>
                <h3>Toda informação ou Dado Individual do Usuário é armazenado em servidores ou meios
                    magnéticos de alta segurança. Salvo com relação às informações que são publicadas na
                    plataforma, a Encurtagro adotará todas as medidas possíveis para manter a
                    confidencialidade e a segurança das informações sigilosas, porém não se responsabilizará
                    por eventuais prejuízos que sejam decorrentes da divulgação de tais informações por parte
                    de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de
                    segurança para acessar as informações dos Usuários. Os Usuários expressamente autorizam
                    que suas informações e Dados Individuais sejam compartilhados pela plataforma com as
                    demais empresas parceiras comerciais, membros do Programa de Proteção à Propriedade
                    Intelectual, autoridades e pessoas físicas ou jurídicas que aleguem ter sido lesadas por
                    Usuários.</h3>
                <h2>6. VIOLAÇÃO NO SISTEMA OU BASE DE DADOS</h2>
                <h3>Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a
                    utilizar, reproduzir ou interferir nas atividades e operações da plataforma, bem como nas
                    contas ou seus bancos de dados. Qualquer intromissão, ou tentativa de intromissão ou
                    atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as
                    proibições estipuladas nestes Termos e condições gerais de uso, tornarão o responsável
                    passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda
                    responsável pelas indenizações por eventuais danos causados.</h3>
                <h2>7. FALHAS NO SISTEMA</h2>
                <h3>A Encurtagro não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelos
                    Usuários em razão de falhas na internet, no sistema ou no servidor utilizado pelos Usuários,
                    decorrentes de condutas de terceiros, caso fortuito ou força maior. A Encurtagro também
                    não é responsável por qualquer vírus que possa atacar o equipamento dos Usuários em
                    decorrência do acesso, utilização ou navegação na internet ou como consequência da
                    transferência de dados, arquivos, imagens, textos, vídeos ou áudio.</h3>
                <h2>8. CADASTRO</h2>
                <h3>Apenas será confirmado o cadastramento dos Usuários que preencherem todos os campos
                    obrigatórios solicitados na(s) página(s) de cadastro de Usuários da plataforma, com
                    informações exatas, precisas e verdadeiras. Ao confirmar o cadastro, os Usuários declaram
                    serem verdadeiras as informações inseridas no cadastro e assumem o compromisso de
                    atualizar os dados inseridos em seu cadastro sempre que houver qualquer alteração das
                    informações de cadastro registradas na plataforma. Ao se cadastrarem, os Usuários poderão
                    utilizar todos os serviços disponibilizados pela plataforma, declarando, para tanto, terem
                    lido, compreendido e aceitado os respectivos Termos e Condições de uso. A Encurtagro não
                    se responsabiliza pela incorreção dos Dados de produtos e Individuais inseridos no cadastro
                    pelos Usuários. Os Usuários garantem e respondem, em qualquer caso, civil e criminalmente
                    pela veracidade, exatidão e autenticidade dos dados inseridos nos cadastros da plataforma.
                    Os Usuários comprometem-se a notificar a Encurtagro imediatamente, e por meio seguro, a
                    respeito de qualquer uso não autorizado de sua conta. O Usuário será o único responsável
                    pelas operações efetuadas em sua conta direta.</h3>
                <h2>9. OBRIGAÇÕES DA ENCURTAGRO</h2>
                <h3>A Encurtagro não se responsabiliza por nenhum problema relacionado à entrega, qualidade,
                    e quaisquer outros aspectos relacionados aos produtos, sendo essa responsabilidade cabível
                    apenas ao Usuário Vendedor do produto. A plataforma realizará apenas o intermédio entre
                    o Usuário Comprador e o Usuário Vendedor.</h3>
                <h2>10. OBRIGAÇÕES DOS USUÁRIOS VENDEDORES</h2>
                <h3>O Usuário Vendedor se responsabilizará totalmente pelos seus produtos anunciados, se
                    comprometendo a entregar corretamente o produto, em boas condições, e declarando
                    serem verdadeiras todas as informações contidas no anúncio, se tornando o responsável
                    passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda
                    responsável pelas indenizações por eventuais danos causados.</h3>
                <h2>11. OBRIGAÇÕES DOS USUÁRIOS COMPRADORES</h2>
                <h3>O Usuário Comprador se responsabilizará totalmente pelas suas informações cadastradas na
                    plataforma, se comprometendo a, após a compra, receber o produto e efetuar
                    corretamente o pagamento do mesmo, se tornando o responsável passível das ações legais
                    pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas
                    indenizações por eventuais danos causados.</h3>
            </div>


            <Footer />
        </div>
    );
}
