import { IoIosArrowBack } from "react-icons/io";
import Footer from "./Footer";

export default function Privacidade() {

    const verde = '#88d374'

    const contato = {
        fontSize: '20px', textAlign: 'center', fontFamily: 'Delius', color: '#fff', marginLeft: '10px'
    }

    const termos = {
        fontSize: '14px', textAlign: 'center', fontFamily: 'Delius', color: '#fff', marginRight: '10px', marginLeft: '10px'
    }

    return (
        <div>
            <a href="/">
                <IoIosArrowBack className="iconeback" />
            </a>

            <div className="termos1">
                <h1>POLÍTICA E PRIVACIDADE</h1>
            </div>
            <div className="termos2">
                <h2>1. DISPOSIÇÕES PRELIMINARES</h2>
                <h3>Fizemos esta Política para que você consiga encontrar, em um único local, todas as
                    informações a respeito do uso de suas informações pessoais coletadas para a prestação
                    personalização de nossos serviços. Por meio desta Política de Privacidade esclareceremos
                    quais dos seus dados são coletados tratados por nós sempre que você acessar nossa
                    plataforma, websites, redes sociais ou quando você nos contatar diretamente, por qualquer
                    meio. Ao se cadastrar em nossa plataforma ou, simplesmente, acessar nossos websites e
                    redes sociais, você concorda com os termos dispostos neste documento. Desse modo, leia
                    atentamente o teor desta Política de Privacidade e entre em contato conosco em caso de
                    dúvidas ou necessidade de esclarecimentos.</h3>
                <h2>2. COMPROMISSO</h2>
                <h3>Na Encurtagro temos o compromisso de cuidar ao máximo das informações dos nossos
                    usuários e proteger a privacidade dos mesmos. Nossa filosofia é que com o uso da nossa
                    plataforma, a compra de produtos possa ser feita de forma fácil, tranquila, transparente e
                    segura sem que você tenha que se preocupar com a confidencialidade dos seus dados.</h3>
                <h2>3. INFORMAÇÕES PESSOAIS</h2>
                <h3>Todas as informações pessoais fornecidas à Encurtagro no processo de cadastro (nome,
                    endereço, telefone) são mantidas sob o mais absoluto sigilo e utilizadas apenas para realizar
                    as entregas e facilitar o contato com os vendedores. A Encurtagro se compromete em não
                    divulgar nenhum tipo de informação pessoal de nossos clientes publicamente.</h3>
                <h2>4. INFORMAÇÕES DE NAVEGAÇÃO</h2>
                <h3>Assim como a maioria dos sites, capturamos algumas informações de navegação na página.
                    Estes dados são capturados por softwares como Google Analytics com a inserção de um
                    cookie no seu navegador e são tratados de forma 100% confidencial, sem nenhum tipo de
                    identificação pessoal dos usuários. Apesar de trabalhar com os melhores sistemas do
                    mercado e do nosso compromisso total com a segurança dos nossos usuários, qualquer
                    website está sujeito a invasões e a casos extremos. Infelizmente não temos controle e não
                    podemos nos responsabilizar por esses casos.</h3>
                <h2>5. CONTATO EM CASO DE DÚVIDAS</h2>
                <h3>Estamos à disposição no caso de qualquer dúvida ou esclarecimento necessário sobre nossa
                    política de privacidade. E-mail: contato@encurtagro.com</h3>
            </div>


            <Footer />

        </div>
    );
}
