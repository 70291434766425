import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { LuShoppingBag } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { FaSeedling } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineLocalPhone } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { FcGallery } from "react-icons/fc";
import Server from '../Servidor';
import { useDados } from "../context/Dados";
import { FiChevronUp } from "react-icons/fi";
import { FiMenu, FiSearch } from "react-icons/fi";
import Logo from '../img/logowhite.png'
import { CiImageOn } from "react-icons/ci";
import { FiPlus, FiMinus, FiTrash } from "react-icons/fi";
import capa from '../img/capa.png'
import CardProduto from "./CardProduto";
import CriarConta from "../Modelo1/CriarConta";
import EntrarConta from "../Modelo1/EntrarConta";
import Perfil from "../Modelo1/Perfil";
import ContinuarCompra from "../Modelo1/ContinuarCompra";

export default function Header({ dadosforn, allprods }) {

    var { dadosmain } = useDados()
    const dados = dadosmain[0].dados
    const [sacola, setsacola] = useState([])

    var total = 0
    sacola.forEach((item) => {
        if (item.tamanho.minimopromo) {
            if (item.qtd >= item.tamanho.minimopromo) {
                total = total + item.tamanho.precopromo * item.qtd
            } else {
                total = total + item.tamanho.preco * item.qtd
            }
        } else {
            total = total + item.tamanho.preco * item.qtd
        }
    })

    const [att, setatt] = useState(0)

    const verdeescuro1 = '#285d56'
    const verdeescuro2 = '#2A4951'
    const verde = '#65b341'
    const branco = '#f8f6f4'
    const chocolate = '#D35400'
    const amarelo1 = '#ffba08'
    const amarelo2 = '#e09f3e'
    const bege = '#CEC2A8'
    const rgb = 'rgba(255, 255, 255, 0.1)'

    const wpp = 'https://api.whatsapp.com/send?phone=' + '55' + dadosforn.telefone.replace(/\D/g, '');
    const insta = 'https://www.instagram.com/' + dadosforn.instagram

    const [display_infos_forn, set_display_infos_forn] = useState(false)
    const [display_entrar_criar, set_display_entrar_criar] = useState(false)
    const [display_entrar, set_display_entrar] = useState(false)
    const [display_criar, set_display_criar] = useState(false)
    const [display_perfil, set_display_perfil] = useState(false)
    const [display_carrinho, set_display_carrinho] = useState(false)
    const [display_filtros, set_display_filtros] = useState(false)
    const [display_produto_selecionado, set_display_produto_selecionado] = useState(false)
    const [display_infos_produtor, set_display_infos_produtor] = useState(false)
    const [display_continuar_compra, set_display_continuar_compra] = useState(false)
    const [produto_selecionado, set_produto_selecionado] = useState(false)
    const [newprods, setnewprods] = useState(allprods)

    function FecharTudo() {
        if (display_infos_forn) {
            set_display_infos_forn(false)
        }
        if (display_entrar_criar) {
            set_display_entrar_criar(false)
        }
        if (display_entrar) {
            set_display_entrar(false)
        }
        if (display_criar) {
            set_display_criar(false)
        }
        if (display_perfil) {
            set_display_perfil(false)
        }
        if (display_produto_selecionado) {
            set_display_produto_selecionado(false)
        }
        if (display_infos_produtor) {
            set_display_infos_produtor(false)
        }
        if (display_carrinho) {
            set_display_carrinho(false)
        }
        if (display_continuar_compra) {
            set_display_continuar_compra(false)
        }
    }

    function VoltarCriarConta() {
        set_display_criar(false)
        set_display_entrar_criar(true)
    }

    function VoltarEntrarConta() {
        set_display_entrar(false)
        set_display_entrar_criar(true)
    }

    function MostrarDetalhesProduto() {
        const [tamanho, settamanho] = useState({ tamanho: produto_selecionado.tamanho, preco: produto_selecionado.preco, minimopromo: produto_selecionado.minimopromo || null, precopromo: produto_selecionado.precopromo || null, minimo: produto_selecionado.minimo || null, estoque: produto_selecionado.estoque || null })
        const [opcao, setopcao] = useState(produto_selecionado.opcoes ? Object.keys(produto_selecionado.opcoes)[0].opcao : false)
        const [att, setatt] = useState(0)
        const [qtd, setqtd] = useState(tamanho.minimo ? parseInt(tamanho.minimo) : 1)

        function Menos() {
            if (qtd > 1) {
                if (tamanho.minimo) {
                    if (qtd > parseInt(tamanho.minimo)) {
                        setqtd(qtd - 1)
                    }
                } else {
                    setqtd(qtd - 1)
                }
            }
        }

        function Mais() {
            if (tamanho.estoque) {
                if (qtd < parseInt(tamanho.estoque)) {
                    setqtd(qtd + 1)
                }
            } else {
                setqtd(qtd + 1)
            }
        }

        function ChangeOpcao(itemString) {
            const item = JSON.parse(itemString);
            setopcao(item.opcao)
        }

        const produtor = produto_selecionado.produtorid ? dadosforn.extensoes.produtores[produto_selecionado.produtorid] : null

        function Adicionar() {
            sacola.push({ infos: produto_selecionado, tamanho, qtd, opcao })
            set_display_produto_selecionado(false)
            set_display_carrinho(true)
        }

        return (
            <div className="modalproduto">
                <IoMdClose className="iconex" style={{ color: '#000' }} onClick={() => FecharTudo()} />

                <div className="modalproduto2">
                    <div className="modalproduto2_icone">
                        {produto_selecionado.icone ? (
                            <img style={{
                                width: '100%',
                                borderRadius: 15
                            }} src={produto_selecionado.icone} alt="Imagem do produto" />
                        ) : (
                            <div style={{ width: '100%', height: 180, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <CiImageOn style={{ fontSize: 120 }} />
                            </div>
                        )}
                    </div>

                    <div className="modalproduto2_infos">
                        <span className="modalproduto_titulo">{produto_selecionado.titulo}</span>

                        <span className="modalproduto_tamanho">{produto_selecionado.tamanho}</span>

                        {produto_selecionado.opcoes ?
                            <select className="select" onChange={(e) => ChangeOpcao(e.target.value)}>
                                {produto_selecionado.opcoes.map(item => (
                                    <option
                                        key={item.opcao}
                                        value={JSON.stringify(item)}>{item.opcao}
                                    </option>
                                ))}
                            </select>
                            : null}

                        <div className="quantidade_container">
                            <button className="btn_quantidade" onClick={() => Menos()}>
                                <FiMinus style={{ fontSize: 25 }} />
                            </button>
                            <span style={{ marginLeft: 20, marginRight: 20 }}>{qtd}</span>
                            <button className="btn_quantidade" onClick={() => Mais()}>
                                <FiPlus style={{ fontSize: 25 }} />
                            </button>
                        </div>

                        <button onClick={() => Adicionar()} className="btn_adicionar">Adicionar (R$ {(tamanho.minimopromo ? qtd >= tamanho.minimopromo ? tamanho.precopromo * qtd : tamanho.preco * qtd : tamanho.preco * qtd).toFixed(2)})</button>

                        {tamanho.minimo ?
                            <div className="continuar_compra_valores">
                                <span className="modalproduto_estoque">Pedido mínimo: </span>
                                <span className="modalproduto_estoque">{tamanho.minimo}</span>
                            </div>
                            : null}

                        {tamanho.estoque ?
                            <div className="continuar_compra_valores">
                                <span className="modalproduto_estoque">Estoque: </span>
                                <span className="modalproduto_estoque">{tamanho.estoque}</span>
                            </div>
                            : null}

                        {produtor ?
                            <span onClick={() => { set_display_produto_selecionado(false); set_display_infos_produtor(true) }} className="produtor_nome">{produtor.nome}</span>
                            : null}
                    </div>
                </div>

                {tamanho.precopromo ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span className="promocao_titulo">Promoção</span>
                        {tamanho.minimopromo == 1 ?
                            <span style={{ marginTop: 5 }}>{Math.round(100 - ((parseFloat(tamanho.precopromo) * 100) / parseFloat(tamanho.preco)))}% de desconto</span>
                            :
                            <span style={{ marginTop: 5 }}>{Math.round(100 - ((parseFloat(tamanho.precopromo) * 100) / parseFloat(tamanho.preco)))}% de desconto para pedidos acima de {tamanho.minimopromo} uni</span>
                        }
                    </div>
                    : null}

                {produto_selecionado.descricao ?
                    <span className="modalproduto_descricao">{produto_selecionado.descricao}</span>
                    : null}
            </div>
        )
    }

    function MostrarDetalhesProdutor() {
        const produtor = dadosforn.extensoes.produtores[produto_selecionado.produtorid]

        const wpp_produtor = 'https://api.whatsapp.com/send?phone=' + '55' + produto_selecionado.produtorid.replace(/\D/g, '');
        const insta_produtor = 'https://www.instagram.com/' + produtor.instagram

        return (
            <div className="modalconta">
                <IoMdClose className="iconex" onClick={() => FecharTudo()} />
                <IoIosArrowBack className="iconeback" onClick={() => { set_display_infos_produtor(false); set_display_produto_selecionado(true) }} />

                <FaSeedling className="seedling" />
                <h1 style={{ fontSize: 26, fontFamily: 'PatrickHand', color: '#fff' }}>{produtor.nome}</h1>
                {produtor.descricao ?
                    <span style={{ fontSize: 21, fontFamily: 'Delius', color: branco, marginTop: 25, textAlign: 'center' }}>{produtor.descricao}</span>
                    : null}

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                    <a target="_blank" href={wpp_produtor} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
                        <MdOutlineLocalPhone className="phone" />
                        <h3 className="contato">{produtor.telefone}</h3>
                    </a>

                    {produtor.instagram ?
                        <a target="_blank" href={insta_produtor} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', wordWrap: 'break-word' }}>
                            <FaInstagram className="phone" />
                            <h3 className="contato">{produtor.instagram}</h3>
                        </a>
                        : null}
                </div>

            </div>
        )
    }

    function ConteudoCarrinho({ item, index }) {
        const [qtd, setqtd] = useState(item.qtd)

        function Menos() {
            if (qtd > 1) {
                if (item.tamanho.minimo) {
                    if (qtd > parseInt(item.tamanho.minimo)) {
                        setqtd(qtd - 1)
                        sacola[index].qtd = item.qtd - 1
                        setatt(att + 1)
                    }
                } else {
                    setqtd(qtd - 1)
                    sacola[index].qtd = item.qtd - 1
                    setatt(att + 1)
                }
            }
        }
        function Mais() {
            if (item.tamanho.estoque) {
                if (qtd < parseInt(item.tamanho.estoque)) {
                    setqtd(qtd + 1)
                    sacola[index].qtd = item.qtd + 1
                    setatt(att + 1)
                }
            } else {
                setqtd(qtd + 1)
                sacola[index].qtd = item.qtd + 1
                setatt(att + 1)
            }
        }
        function Remover() {
            sacola.splice(index, 1);
            setatt(att + 1)
        }

        return (
            <div style={{ display: 'flex', margin: 10 }}>
                <div style={{ width: '20%' }}>
                    {item.infos.icone ? (
                        <img style={{
                            width: '100%',
                            borderRadius: 15
                        }} src={item.infos.icone} alt="Imagem do produto" />
                    ) : (
                        <div style={{ width: '100%', height: 80, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <CiImageOn style={{ fontSize: 60 }} />
                        </div>
                    )}
                </div>
                <div style={{ paddingLeft: 10 }}>
                    <div>
                        <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.infos.titulo}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.tamanho.tamanho}</span>
                        {item.opcao ?
                            <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.opcao}</span>
                            : null}
                    </div>
                    <div>
                        <span style={{ fontSize: 16, marginTop: 10, fontWeight: 700, color: '#55a630', fontFamily: 'Delius' }}>R$ {item.tamanho.precopromo ? item.qtd >= item.tamanho.minimopromo ? item.tamanho.precopromo : item.tamanho.preco : item.tamanho.preco}</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div className="quantidade_container">
                            <button className="btn_quantidade" onClick={() => Menos()}>
                                <FiMinus style={{ fontSize: 17 }} />
                            </button>
                            <span style={{ marginLeft: 5, marginRight: 5, textAlign: 'center' }}>{qtd}</span>
                            <button className="btn_quantidade" onClick={() => Mais()}>
                                <FiPlus style={{ fontSize: 17 }} />
                            </button>
                        </div>
                        <FiTrash onClick={() => Remover()} style={{ fontSize: 17, padding: 10, cursor: 'pointer', position: 'absolute', right: 15 }} />

                    </div>

                </div>
            </div>
        )
    }

    function ContinuarCompraLogin() {
        set_display_continuar_compra(false)
        set_display_entrar_criar(true)
    }

    function PedidoFinalizado() {
        sacola.splice(0, sacola.length)
        setatt(att + 1)
    }

    function FiltroSearch({ text }) {
        if (text) {
            const newData = allprods.filter(function (item) {
                const titulo = item.titulo
                    ? item.titulo.toUpperCase()
                    : ''.toUpperCase();

                const textData = text.toUpperCase();
                return titulo.indexOf(textData) > -1
            });
            setnewprods(newData);
        } else {
            setnewprods(allprods);
        }
    }

    return (
        <div style={{ height: '100%' }}>

            <div className="modelo2_capa">
                <img style={{
                    width: '100%',
                    filter: 'brightness(60%)'
                }} src={dadosforn.fundoforn || capa} />
            </div>

            {/* <div style={{ cursor: 'pointer', position: 'relative', top: '-5%', maxWidth: '50%', flexDirection: 'row', display: 'flex', padding: 15, background: branco, marginLeft: 'auto', marginRight: 'auto', borderRadius: 15 }}
                onClick={() => set_display_infos_forn(true)}>
                <img src={dadosforn.iconeforn} style={{ width: 80, height: 80, borderRadius: 10 }} />

                <h1 style={{ fontSize: 24, fontFamily: 'PatrickHand', color: '#000', padding: 20 }}>{dadosforn.nomeforn}</h1>
            </div> */}

            <div className="modelo2_header">
                <div className="modelo2_forn"
                    onClick={() => set_display_infos_forn(true)}>
                    {dadosforn.iconeforn ?
                        <img src={dadosforn.iconeforn} className="modelo2_forn_img" />
                        : <CiImageOn style={{ fontSize: 80 }} />
                    }

                    <h1 className="modelo2_forn_text">{dadosforn.nomeforn}</h1>
                </div>

                <div style={{ cursor: 'pointer', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', marginLeft: 5, padding: 15, background: branco, borderRadius: 15 }}
                    onClick={() => dados ? set_display_perfil(true) : set_display_entrar_criar(true)}>
                    <FiUser style={{ color: '#000', fontSize: 30 }} />
                    <span style={{ color: '#000', fontSize: 17, marginLeft: 10, fontFamily: 'Delius' }}>{dados ? dados.nome : 'Entre ou cadastre-se'}</span>
                </div>
            </div>

            {/* <div className="modelo2_search">
                <FiSearch style={{ fontSize: 25, color: '#000', alignSelf: 'center' }} />
                <input onChange={(text) => FiltroSearch({ text: text.target.value })} className="modelo2_search_input" placeholder="Pesquisar..."></input>
            </div> */}

            {sacola?.length >= 1 ?
                <div className="carrinho_flutuante" onClick={() => set_display_carrinho(true)}>
                    <button className="carrinho_flutuante_btn">
                        <span className="carrinho_flutuante_qtd">{sacola.length}</span>
                        <span className="carrinho_flutuante_text">Meu Pedido</span>
                        <span>R$ {total.toFixed(2)}</span>
                    </button>
                </div>
                : null}

            {display_infos_forn || display_entrar_criar || display_entrar || display_criar || display_perfil || display_produto_selecionado || display_infos_produtor || display_carrinho || display_continuar_compra ?
                <div className="fundoescuro" onClick={() => FecharTudo()} />
                : null}

            {display_infos_forn ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => FecharTudo()} />
                    <FaSeedling className="seedling" />
                    <h1 style={{ fontSize: 26, fontFamily: 'PatrickHand', color: '#fff' }}>{dadosforn.nomeforn}</h1>
                    {dadosforn.descricaoforn ?
                        <span className="descricaoforn">{dadosforn.descricaoforn}</span>
                        : null}

                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                        <a target="_blank" href={wpp} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
                            <MdOutlineLocalPhone className="phone" />
                            <h3 className="contato">{dadosforn.telefone}</h3>
                        </a>

                        {dadosforn.instagram ?
                            <a target="_blank" href={insta} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
                                <FaInstagram className="phone" />
                                <h3 className="contato">{dadosforn.instagram}</h3>
                            </a>
                            : null}
                    </div>

                </div>
                : null}

            {display_entrar_criar ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => FecharTudo()} />
                    <FiUser style={{ color: '#fff', fontSize: 60, marginBottom: 10 }} />

                    <span onClick={() => { set_display_entrar_criar(false); set_display_entrar(true) }} className="modal_conta_entrar_criar">Entrar</span>
                    <span onClick={() => { set_display_entrar_criar(false); set_display_criar(true) }} className="modal_conta_entrar_criar">Criar conta</span>
                </div>
                : null}

            {display_criar ?
                <CriarConta
                    voltar={() => VoltarCriarConta()}
                    fechar={() => FecharTudo()}
                />
                : null}

            {display_entrar ?
                <EntrarConta
                    voltar={() => VoltarEntrarConta()}
                    fechar={() => FecharTudo()}
                />
                : null}

            {display_perfil ?
                <Perfil
                    fechar={() => FecharTudo()}
                />
                : null}

            {display_produto_selecionado ?
                <MostrarDetalhesProduto />
                : null}

            {display_infos_produtor ?
                <MostrarDetalhesProdutor />
                : null}

            {display_continuar_compra ?
                <ContinuarCompra
                    fechar={() => FecharTudo()}
                    sacola={sacola}
                    total={total}
                    dadosforn={dadosforn}
                    login={() => ContinuarCompraLogin()}
                    finalizado={() => PedidoFinalizado()}
                />
                : null}

            <div className={`carrinho ${display_carrinho ? 'carrinho-aberto' : ''}`}>
                <div className="carrinho_area_fechar">
                    <IoMdClose className="carrinho_iconex" onClick={() => FecharTudo()} />
                </div>

                {sacola.length >= 1 ?
                    <div style={{ height: '100%' }}>
                        <div style={{ height: '75%', overflowY: 'auto' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {sacola.map((item, index) => {
                                    return <ConteudoCarrinho key={index} item={item} index={index} />
                                })}
                            </div>
                        </div>

                        <div className="carrinho_footer">
                            <span className="carrinho_footer_preco">R$ {total.toFixed(2)}</span>
                            <button onClick={() => { set_display_carrinho(false); set_display_continuar_compra(true) }} className="carrinho_footer_btn">Continuar Compra</button>
                        </div>
                    </div>
                    : null}
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 100 }}>
                {newprods.map((item, index) => {
                    return <CardProduto key={index} item={item} detalhes={() => { set_produto_selecionado(item); set_display_produto_selecionado(true) }} />
                })}
            </div>

        </div>
    );
}
