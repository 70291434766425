import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Server from '../Servidor';
import cidades from '../Locais/Cidades'
import { useDados } from "../context/Dados";

export default function CriarConta({ voltar, fechar }) {

    var { dadosmain } = useDados()

    const [display_criar1, set_display_criar1] = useState(true)
    const [display_criar2, set_display_criar2] = useState(false)
    const [display_criar3, set_display_criar3] = useState(false)
    const [display_criar4, set_display_criar4] = useState(false)
    const [display_criar5, set_display_criar5] = useState(false)
    const [display_criar6, set_display_criar6] = useState(false)

    const [email, setemail] = useState('')
    const [senha, setsenha] = useState('')
    const [nome, setnome] = useState('')
    const [rua, setrua] = useState('')
    const [numero, setnumero] = useState('')
    const [complemento, setcomplemento] = useState('')
    const [telefone, settelefone] = useState('')

    const [cidadeinput, setcidadeinput] = useState('')
    const [cidade, setcidade] = useState('')
    const [drop, setdrop] = useState(false)
    const [loadbtn, setloadbtn] = useState(false)

    async function Next() {
        if (display_criar1) {
            if (nome.length < 1 || nome.length > 30) {
                alert('Preencha todos os campos!')
            } else {
                set_display_criar1(false)
                set_display_criar2(true)
            }
        }
        if (display_criar2) {
            var re = /\S+@\S+\.\S+/;
            const emailtrim = email.trim().toLowerCase()
            const teste = re.test(emailtrim);
            if (email.length == 0 || !teste) {
                alert('Email inválido!')
            } else {
                if (senha.length < 6) {
                    alert('A senha deve possuir no mínimo 6 dígitos')
                } else {
                    setloadbtn(true)

                    try {
                        const resposta = await Server({ codigo: 'verificar_email_existente', email: emailtrim });
                        setloadbtn(false)

                        if (resposta.error) {
                            console.log(resposta.error)
                            alert('Algum erro ocorreu!')
                        } else {
                            if (resposta.existe) {
                                alert('Já existe uma conta com este email')
                            } else {
                                set_display_criar2(false)
                                set_display_criar3(true)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        alert('Algum erro ocorreu!')
                    }
                }
            }
        }
        if (display_criar3) {
            set_display_criar3(false)
            set_display_criar4(true)
        }
        if (display_criar4) {
            if (rua.length < 1 || numero.length < 1) {
                alert('Preencha todos os campos!')
            } else {
                if (isNaN(numero)) {
                    alert('Insira um número válido')
                } else {
                    set_display_criar4(false)
                    set_display_criar5(true)
                }
            }
        }
        if (display_criar5) {
            setloadbtn(true)

            const resposta = await Server({ codigo: 'criar_conta', email: email.trim().toLowerCase(), senha, nome });

            if (resposta.error) {
                console.log(resposta.error)
                alert('Algum erro ocorreu')
            } else {
                const resposta2 = await Server({ codigo: 'gerar_coordenadas', rua, numero, cidade })

                if (resposta2.error) {
                    console.log(resposta2.error)
                    alert('Algum erro ocorreu')
                } else {
                    const resposta3 = await Server({
                        codigo: 'alterar_endereco',
                        usuario: resposta.usuario,
                        rua: rua,
                        numero: numero,
                        cidade: cidade,
                        telefone: telefone,
                        complemento: complemento || null,
                        latitude: resposta2.latitude,
                        longitude: resposta2.longitude
                    });
                    if (resposta3.error) {
                        console.log(resposta3.error)
                        alert('Algum erro ocorreu')
                    } else {
                        const newdados = {
                            nome: nome,
                            email: email,
                            usuario: resposta.usuario,
                            rua: rua,
                            numero: numero,
                            cidade: cidade,
                            telefone: telefone,
                            complemento: complemento || null,
                            latitude: resposta2.latitude,
                            longitude: resposta2.longitude,
                            login: email.trim().toLowerCase(),
                            senha: senha
                        }

                        const dadosasync = {
                            login: email.trim().toLowerCase(),
                            senha: senha
                        }

                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);
                        //nao pode salvar todos os dados no async, pq as vezes o usuario muda
                        //pelo app e fica salvo no site

                        dadosmain[0].dados = newdados

                        setloadbtn(false)
                        set_display_criar5(false)
                        set_display_criar6(true)
                    }
                }
            }
        }
    }

    function HandleInput({ text }) {
        setcidadeinput(text.target.value)
        if (!drop) {
            setdrop(true)
        }
    }

    function HandleTelefone({ text }) {
        let valor = text.replace(/\D/g, '');

        // Adiciona os parênteses e traço conforme o tamanho do valor
        if (valor.length > 2) {
            valor = `(${valor.slice(0, 2)})${valor.slice(2)}`;
        }
        if (valor.length > 8) {
            valor = `${valor.slice(0, 9)}-${valor.slice(9)}`;
        }
        settelefone(valor)
    }

    return (
        <div>
            {display_criar1 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => voltar()} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Nome</span>
                        <input value={nome} onChange={(text) => setnome(text.target.value)} className="modal_conta_inputs" placeholder="Nome"></input>

                        <span onClick={() => Next()} className="btncontinuar">Continuar</span>

                    </div>
                </div>
                : null}

            {display_criar2 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_criar2(false); set_display_criar1(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Email</span>
                        <input value={email} onChange={(text) => setemail(text.target.value)} className="modal_conta_inputs" placeholder="Email"></input>

                        <span className="modal_conta_titulos">Senha</span>
                        <input type="password" value={senha} onChange={(text) => setsenha(text.target.value)} className="modal_conta_inputs" placeholder="Senha"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => Next()} className="btncontinuar">Continuar</span>
                        }

                    </div>
                </div>
                : null}

            {display_criar3 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_criar3(false); set_display_criar2(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Selecione sua cidade</span>
                        <input value={cidadeinput} onChange={(text) => HandleInput({ text })} className="modal_conta_inputs" placeholder="Cidade"></input>

                        {drop ?
                            <div className="dropdown_cidades">
                                <div className="dropdown_scroll">
                                    {cidades
                                        .filter(cidade => cidade.toLowerCase().includes(cidadeinput.toLowerCase()))
                                        .map((cidade, index) => (
                                            <span onClick={() => { setcidade(cidade); setcidadeinput(cidade); setdrop(false) }} key={index} className="dropdown_texto">{cidade}</span>
                                        ))}
                                </div>
                            </div>
                            : null}

                        {cidade && !drop ?
                            <span onClick={() => Next()} className="btncontinuar">Continuar</span>
                            : null}
                    </div>
                </div>
                : null}

            {display_criar4 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_criar4(false); set_display_criar3(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Rua</span>
                        <input value={rua} onChange={(text) => setrua(text.target.value)} className="modal_conta_inputs" placeholder="Rua"></input>

                        <span className="modal_conta_titulos">Número</span>
                        <input value={numero} onChange={(text) => setnumero(text.target.value)} className="modal_conta_inputs" placeholder="Número"></input>

                        <span onClick={() => Next()} className="btncontinuar">Continuar</span>

                    </div>
                </div>
                : null}

            {display_criar5 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />
                    <IoIosArrowBack className="iconeback" onClick={() => { set_display_criar5(false); set_display_criar4(true) }} />

                    <div className="modalconta_div">

                        <span className="modal_conta_titulos">Complemento (opcional)</span>
                        <input value={complemento} onChange={(text) => setcomplemento(text.target.value)} className="modal_conta_inputs" placeholder="Complemento"></input>

                        <span className="modal_conta_titulos">Telefone</span>
                        <input maxLength={14} value={telefone} onChange={(text) => HandleTelefone({ text: text.target.value })} className="modal_conta_inputs" placeholder="(xx)xxxxx-xxxx"></input>

                        {loadbtn ?
                            <span className="btncontinuar_load" />
                            :
                            <span onClick={() => Next()} className="btncontinuar">Criar conta</span>
                        }

                    </div>
                </div>
                : null}

            {display_criar6 ?
                <div className="modalconta">
                    <IoMdClose className="iconex" onClick={() => fechar()} />

                    <div className="modalconta_div">
                        <span style={{ color: '#fff', fontSize: 21, fontFamily: 'Delius', textAlign: 'center', marginBottom: 20 }}>Conta criada com sucesso!</span>
                        <span onClick={() => fechar()} className="btncontinuar">OK</span>
                    </div>
                </div>
                : null}

        </div>
    );
}
